import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';

const NegociationModal = (props) => {

    return (
        <Modal isOpen={props.ModalNegociationOpen} toggle={props.toggleModalNegociation} className="form-input">
            <ModalHeader toggle={props.toggleModalNegociation}>Négociation de la simulation </ModalHeader>
            <ModalFooter>
                <Button color="secondary" className="mr-2" onClick={props.toggleModalNegociation} >Non</Button>
                <Button color="primary" onClick={props.handleValidate} >Oui</Button>
            </ModalFooter>
        </Modal>
    );
};

export default NegociationModal;