import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Label
} from 'reactstrap';
import { getEmployeeImage, updateEmployeeImage, createUserForEmployee, getEmployeeById } from "../../services/configService";
import './EmployeeDetailsModal.scss';
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import HistoryIcon from '@material-ui/icons/History';
import showToast from "../../components/NotificationToast/toastUtils";
import { useDispatch } from "react-redux";

const EmployeeDetailsModal = ({ isOpen, toggle, selectedEmployee, handleEdit }) => {
    const [employeeData, setEmployeeData] = useState({
        id: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        duty: "",
        picture: "",
        nationalty: "",
        address: "",
        socialSecurityNumber: "",
        tempraryPswd: "",
        isUser: false,
        isTemporary: false
    });

    const [imageUrl, setImageUrl] = useState("");
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        if (selectedEmployee) {
            setEmployeeData({
                id: selectedEmployee.id || "",
                firstName: selectedEmployee.firstName || "",
                lastName: selectedEmployee.lastName || "",
                birthDate: selectedEmployee.birthDate || "",
                email: selectedEmployee.email || "",
                phoneNumber: selectedEmployee.phoneNumber || "",
                duty: selectedEmployee.duty || "",
                picture: selectedEmployee.picture || "",
                nationalty: selectedEmployee.nationalty || "",
                address: selectedEmployee.address || "",
                socialSecurityNumber: selectedEmployee.socialSecurityNumber || "",
                tempraryPswd: selectedEmployee.tempraryPswd || "",
                isUser: selectedEmployee.isUser || false,
                isTemporary: selectedEmployee.isTemporary || false
            });

            getEmployeeImage(selectedEmployee.id)
                .then((response) => {
                    const blob = response.data;
                    const imageUrl = URL.createObjectURL(blob);
                    setImageUrl(imageUrl);
                    setImageError(false);
                })
                .catch(() => {
                    setImageUrl('');
                    setImageError(true);
                });
        }
    }, [selectedEmployee]);

    const handleChangePicture = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const id = selectedEmployee.id;

                try {
                    await updateEmployeeImage(id, file);
                    showToast("Image mise à jour avec succès!");

                    const imageUrl = URL.createObjectURL(file);
                    setImageUrl(imageUrl);
                    setImageError(false);
                } catch (error) {
                    showToast("Une erreur est survenue lors de la mise à jour de l'image.","error");
                }
            }
        };

        input.click();
    };

    const handleCreateAccess = async (e) => {
        e.preventDefault();

        try {
            await createUserForEmployee(employeeData.id);
            showToast("L'accès a été créé avec succès !");

            await reloadEmployeeData();
        } catch (error) {
            console.log(error)
            showToast("Une erreur est survenue :","error");
        }
    };

    const reloadEmployeeData = async () => {
        try {
            const response = await getEmployeeById(employeeData.id);
            setEmployeeData({
                id: response.data.id,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                birthDate: response.data.birthDate,
                email: response.data.email,
                phoneNumber: response.data.phoneNumber,
                duty: response.data.duty,
                picture: response.data.picture,
                nationalty: response.data.nationalty,
                address: response.data.address,
                socialSecurityNumber: response.data.socialSecurityNumber,
                tempraryPswd: response.data.tempraryPswd,
                isUser: response.data.isUser,
                isTemporary: response.data.isTemporary
            });

            const imageResponse = await getEmployeeImage(employeeData.id);
            const imageUrl = URL.createObjectURL(imageResponse.data);
            setImageUrl(imageUrl);
            setImageError(false);
        } catch (error) {
            showToast("Erreur lors du rechargement des données de l'employé.","error");
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>
                <Row>
                    <Col md={3}>
                        <div className="employee-image-container">
                            {imageError ? (
                                <div className="employee-image-placeholder">
                                    Indisponible
                                </div>
                            ) : (
                                <img
                                    src={imageUrl || employeeData.picture || 'https://via.placeholder.com/100'}
                                    alt="Photo de l'employé"
                                    className="employee-image"
                                    onError={() => setImageError(true)}
                                />
                            )}
                        </div>
                    </Col>
                    <Col md={9} className="text-center">
                        <h3>{employeeData.firstName} {employeeData.lastName}</h3>
                    </Col>
                    <Col>
                        <Button
                            color="secondary"
                            className="btn-sm mt-2 mr-2"
                            onClick={handleChangePicture}
                        >
                            <i className="eva eva-image-outline" />
                        </Button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="birthDate">Date de Naissance:</Label>
                            <div>{employeeData.birthDate ? new Date(employeeData.birthDate).toLocaleDateString() : 'N/A'}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email:</Label>
                            <div>{employeeData.email || 'N/A'}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="phoneNumber">Téléphone:</Label>
                            <div>{employeeData.phoneNumber || 'N/A'}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="duty">Fonction:</Label>
                            <div>{employeeData.duty || 'N/A'}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="nationality">Nationalité:</Label>
                            <div>{employeeData.nationalty || 'N/A'}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Adresse:</Label>
                            <div>{employeeData.address || 'N/A'}</div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="socialSecurityNumber">Numéro de Sécurité Sociale:</Label>
                            <div>{employeeData.socialSecurityNumber || 'N/A'}</div>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <div>
                                <PersonOutlineIcon
                                    className="icon"
                                    style={{
                                        fontSize: "2rem",
                                        color: employeeData.isUser ? "green" : "red",
                                    }}
                                />
                                {employeeData.isUser ? (
                                    <a href="#" onClick={() => showToast('Réinitialiser le mot de passe')}>réinitialiser MP</a>
                                ) : (
                                    <a href="#" onClick={handleCreateAccess}>Créer un Accès</a>
                                )}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label for="isTemporary"><HistoryIcon
                                className="icon"
                                style={{
                                    fontSize: "2rem",
                                    color: "orange",
                                }}
                            /> MP :</Label>{employeeData.tempraryPswd || ''}
                        </FormGroup>
                    </Col>
                </Row>
                <Button color="secondary" onClick={toggle}>
                    Fermer
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EmployeeDetailsModal;
