// toastUtils.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '../Notification/Notification'; // Adjust the path as necessary
import './toastUtils.module.scss'
const showToast = (message,
                   type = "success",
                   withIcon = true,
                   autoClose = 4000,
                   position = "top-right"
) => {
    toast(
        <Notification
            type={type}
            withIcon={withIcon}
            message={message}
        />,
        {
            autoClose,
            closeButton: false,
            hideProgressBar: true,
            position,
            className: "custom-toast", // Apply a custom class
            bodyClassName: "custom-toast-body" // Apply a custom class to the body if needed
        }
    );
};

export default showToast;