import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Widget from "../../components/Widget/Widget";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import {
  getLegalstatusAll,
  saveLegalStatus,
  updateLegalStatus,
  deleteLegalStatus,
} from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";

const Legalstatus = () => {
  const dispatch = useDispatch();

  // States
  const [legalStatuses, setLegalStatuses] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [modalState, setModalState] = useState({
    add: false,
    edit: false,
    delete: false,
    view: false,  // Ajout de la modal view
  });
  const [formData, setFormData] = useState({
    codeLabel: "",
    Label: "",
    label: "",
  });
  const [loading, setLoading] = useState(false);

  // Colonnes pour le tableau
  const columns = [
    { dataField: "codeLabel", text: "Code", sort: true },
    { dataField: "Label", text: "Label", sort: true },
    { dataField: "label", text: "Description", sort: true },
  ];

  // Fonction pour récupérer les statuts juridiques
  const fetchLegalStatuses = async () => {
    dispatch(startLoading());
    try {
      const res = await getLegalstatusAll();
      setLegalStatuses(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    fetchLegalStatuses();
  }, [dispatch]);

  // Gestion des modals
  const toggleModal = (modal) => {
    setModalState((prev) => ({ ...prev, [modal]: !prev[modal] }));
    if (modal !== "edit" && modal !== "view") resetFormData();  // Réinitialiser si pas en mode edit/view
  };

  const resetFormData = () => {
    setFormData({
      codeLabel: "",
      Label: "",
      label: "",
    });
  };

  // Fonction pour gérer les clics de ligne pour visualisation
  const handleRowClick = (row) => {
    setSelectedStatus(row);
    setFormData(row);  // Remplit le formulaire avec les données du statut sélectionné
    toggleModal("view");  // Ouvre le modal de visualisation
  };

  // Fonction pour gérer l'ajout
  const handleAddLegalStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await saveLegalStatus(formData);
      showToast("Statut juridique ajouté avec succès.");
      toggleModal("add");
      fetchLegalStatuses();
    } catch (error) {
      showToast("Erreur lors de l'ajout du statut juridique.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la modification
  const handleEditLegalStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateLegalStatus(selectedStatus.id, formData);
      showToast("Statut juridique modifié avec succès.");
      toggleModal("edit");
      fetchLegalStatuses();
    } catch (error) {
      showToast("Erreur lors de la modification du statut juridique.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la suppression
  const handleDeleteLegalStatuses = async () => {
    const idsToDelete = selectedRows.map((row) => row.id);
    try {
      await deleteLegalStatus(idsToDelete);
      showToast("Statut(s) juridique(s) supprimé(s) avec succès.");
      setSelectedRows([]);
      fetchLegalStatuses();
    } catch (error) {
      showToast("Erreur lors de la suppression des statuts juridiques.", "error");
    }
    toggleModal("delete");
  };

  // Gérer la sélection des lignes dans le tableau
  const handleSelectRow = (row, isSelect) => {
    setSelectedRows((prev) =>
        isSelect ? [...prev, row] : prev.filter((r) => r.id !== row.id)
    );
  };

  const handleSelectAll = (isSelect, rows) => {
    setSelectedRows(isSelect ? rows : []);
  };

  return (
      <Row className="mb-4">
        <Col>
          <Widget>
            <div className="button-container mb-1 mt-2 mr-2">
              <Button color="primary" className="mr-2" onClick={() => toggleModal("add")}>
                <i className="eva eva-plus-outline icon" />
              </Button>
              {selectedRows.length === 1 && (
                  <Button color="primary" className="mr-2" onClick={() => toggleModal("edit")}>
                    <i className="eva eva-edit-outline icon" />
                  </Button>
              )}
              {selectedRows.length > 0 && (
                  <Button color="danger" className="mr-2" onClick={() => toggleModal("delete")}>
                    <i className="eva eva-trash-2-outline icon" />
                  </Button>
              )}
            </div>
            <BootstrapTable
                keyField="id"
                data={legalStatuses}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                hover
                striped
                condensed
                selectRow={{
                  mode: "checkbox",
                  onSelect: handleSelectRow,
                  onSelectAll: handleSelectAll,
                }}
                rowEvents={{
                  onClick: (e, row) => handleRowClick(row),  // Visualisation des détails
                }}
            />
          </Widget>
        </Col>

        {/* Modal pour ajouter un statut juridique */}
        <Modal isOpen={modalState.add} toggle={() => toggleModal("add")}>
          <ModalHeader toggle={() => toggleModal("add")}>Ajouter un Statut Juridique</ModalHeader>
          <Form onSubmit={handleAddLegalStatus}>
            <ModalBody>
              <FormGroup>
                <Label for="codeLabel">Code</Label>
                <Input
                    id="codeLabel"
                    value={formData.codeLabel}
                    onChange={(e) => setFormData({ ...formData, codeLabel: e.target.value })}
                    placeholder="Code"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="Label">Label</Label>
                <Input
                    id="Label"
                    value={formData.Label}
                    onChange={(e) => setFormData({ ...formData, Label: e.target.value })}
                    placeholder="Label"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="label">Description</Label>
                <Input
                    id="label"
                    value={formData.label}
                    onChange={(e) => setFormData({ ...formData, label: e.target.value })}
                    placeholder="Description"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal("add")}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Ajouter
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {/* Modal pour éditer un statut juridique */}
        <Modal isOpen={modalState.edit} toggle={() => toggleModal("edit")}>
          <ModalHeader toggle={() => toggleModal("edit")}>Modifier un Statut Juridique</ModalHeader>
          <Form onSubmit={handleEditLegalStatus}>
            <ModalBody>
              <FormGroup>
                <Label for="codeLabel">Code</Label>
                <Input
                    id="codeLabel"
                    value={formData.codeLabel}
                    onChange={(e) => setFormData({ ...formData, codeLabel: e.target.value })}
                    placeholder="Code"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="Label">Label</Label>
                <Input
                    id="Label"
                    value={formData.Label}
                    onChange={(e) => setFormData({ ...formData, Label: e.target.value })}
                    placeholder="Label"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="label">Description</Label>
                <Input
                    id="label"
                    value={formData.label}
                    onChange={(e) => setFormData({ ...formData, label: e.target.value })}
                    placeholder="Description"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal("edit")}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Modifier
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {/* Modal pour voir les détails d'un statut juridique */}
        <Modal isOpen={modalState.view} toggle={() => toggleModal("view")}>
          <ModalHeader toggle={() => toggleModal("view")}>Détails du Statut Juridique</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="codeLabel">Code</Label>
              <Input
                  id="codeLabel"
                  value={formData.codeLabel}
                  disabled
              />
            </FormGroup>
            <FormGroup>
              <Label for="Label">Label</Label>
              <Input
                  id="Label"
                  value={formData.Label}
                  disabled
              />
            </FormGroup>
            <FormGroup>
              <Label for="label">Description</Label>
              <Input
                  id="label"
                  value={formData.label}
                  disabled
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal("view")}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>

        {/* Modal pour confirmer la suppression */}
        <Modal isOpen={modalState.delete} toggle={() => toggleModal("delete")}>
          <ModalHeader toggle={() => toggleModal("delete")}>
            Supprimer les Statuts Juridiques
          </ModalHeader>
          <ModalBody>
            Voulez-vous vraiment supprimer les statuts sélectionnés ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal("delete")}>
              Annuler
            </Button>
            <Button color="danger" onClick={handleDeleteLegalStatuses}>
              Supprimer
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
  );
};

export default Legalstatus;
