import React, { useState, useEffect } from "react";
import {
  getEmployees,
  saveEmployee,
  deleteEmployees,
  updateEmployee
} from "../../services/configService";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./EmployeesPage.scss";
import showToast from "../../components/NotificationToast/toastUtils";
import { startLoading, stopLoading } from "../../actions/loading";
import { useDispatch } from "react-redux";
import AddEmployeeModal from "./AddEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";
import EmployeeDetailsModal from "./EmployeeDetailsModal";

const Employees = () => {
  const [formList, setFormList] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const dispatch = useDispatch();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const columns = [
    { dataField: "lastName", text: "Nom", sort: true },
    { dataField: "firstName", text: "Prenom" },
    {
      dataField: "birthDate",
      text: "Date Naissance",
      formatter: (cell) => formatDate(cell)
    },
    { dataField: "socialSecurityNumber", text: "N Sec Sos" },
    { dataField: "email", text: "Mail" },
    { dataField: "duty", text: "Fonction" }
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
    )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filter employees whenever searchTerm or formList changes
    const lowercasedTerm = searchTerm.toLowerCase();
    const filtered = formList.filter((employee) =>
        Object.values(employee)
            .join(" ")
            .toLowerCase()
            .includes(lowercasedTerm)
    );
    setFilteredEmployees(filtered);
  }, [searchTerm, formList]);

  const fetchData = async () => {
    dispatch(startLoading());
    try {
      const res = await getEmployees();
      setFormList(res.data);
      setFilteredEmployees(res.data);
    } catch (error) {
      showToast("Erreur " + error.message, "error");
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleRowClick = (row) => {
    setSelectedEmployee(row);
    setIsDetailsModalOpen(true);
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    setSelectedRows(isSelect ? rows : []);
  };

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const handleAdd = async (employeeData) => {
    try {
      await saveEmployee(employeeData);
      showToast("L'employé a été enregistré avec succès.");
      toggleAddModal();
      fetchData();
    } catch (error) {
      showToast("L'employé n'a pas pu être enregistré. " + error.message, "error");
    }
  };

  const handleEdit = async (updatedEmployee) => {
    try {
      await updateEmployee(updatedEmployee.id, updatedEmployee);
      showToast("L'employé a été modifié avec succès.");
      toggleEditModal();
      fetchData();
    } catch (error) {
      showToast("L'employé n'a pas pu être modifié. " + error.message, "error");
    }
  };

  const handleDelete = async () => {
    const idsToDelete = selectedRows.map((row) => row.id);
    try {
      await deleteEmployees(idsToDelete);
      showToast("Les employés sélectionnés ont été supprimés avec succès.");
      toggleDeleteModal();
      fetchData();
      setSelectedRows([]);
    } catch (error) {
      showToast("Erreur lors de la suppression des employés : " + error.message, "error");
    }
  };

  return (
      <div>
        <Row className="mb-4">
          <Col>
            <Widget>
              <Input
                  type="text"
                  placeholder="Rechercher un employé..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mb-3"
              />
              <div className="button-container mb-1 mt-2">
                <Button color="primary" onClick={toggleAddModal}>
                  <i className="eva eva-plus-outline icon" />
                </Button>
                {selectedRows.length === 1 && (
                    <Button
                        color="primary"
                        onClick={() => {
                          setSelectedEmployee(selectedRows[0]);
                          toggleEditModal();
                        }}
                    >
                      <i className="eva eva-edit-outline icon" />
                    </Button>
                )}
                {selectedRows.length > 0 && (
                    <Button color="danger" onClick={toggleDeleteModal}>
                      <i className="eva eva-trash-2-outline icon" />
                    </Button>
                )}
              </div>
              <BootstrapTable
                  keyField="id"
                  data={filteredEmployees}
                  columns={columns}
                  rowEvents={{ onClick: (e, row) => handleRowClick(row) }}
                  pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                  wrapperClasses="table-responsive"
                  striped
                  hover
                  condensed
                  selectRow={{
                    mode: "checkbox",
                    onSelect: handleOnSelect,
                    onSelectAll: handleOnSelectAll
                  }}
                  bootstrap4
                  classes="table-sm"
              />
            </Widget>
          </Col>
        </Row>
        <AddEmployeeModal isOpen={isAddModalOpen} toggle={toggleAddModal} handleAdd={handleAdd} />
        <EditEmployeeModal
            isOpen={isEditModalOpen}
            toggle={toggleEditModal}
            selectedEmployee={selectedEmployee}
            handleEdit={handleEdit}
        />
        <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Confirmer la suppression</ModalHeader>
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer les employés sélectionnés ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Annuler
            </Button>
            <Button color="danger" onClick={handleDelete}>
              Supprimer
            </Button>
          </ModalFooter>
        </Modal>
        <EmployeeDetailsModal
            isOpen={isDetailsModalOpen}
            toggle={() => setIsDetailsModalOpen(false)}
            selectedEmployee={selectedEmployee}
        />
      </div>
  );
};

export default Employees;
