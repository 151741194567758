// Helper functions
export function getWeekNumber(date) {
    if (!date || !(date instanceof Date)) {
        return ""; // Renvoie une chaîne vide si la date est invalide
    }
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / (1000 * 60 * 60 * 24);
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getDayOfWeek(date) {
    if (!date || !(date instanceof Date)) {
        return ""; // Renvoie une chaîne vide si la date est invalide
    }
    const daysOfWeek = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return daysOfWeek[date.getDay()];
}

export function isWeekend(date) {
    if (!date || !(date instanceof Date)) {
        return false; // Considère qu'une date invalide n'est pas un week-end
    }
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Dimanche, 6 = Samedi
}

export const getDaysInMonth = (month, year) => {

    if (!month || !year) {
        return []; // Si le mois ou l'année ne sont pas valides, renvoie un tableau vide
    }

    const date = new Date(year, month - 1, 1);
    const days = [];

    // S'assurer que la date est valide
    if (isNaN(date.getTime())) {
        return []; // Renvoie un tableau vide si la date n'est pas valide
    }

    while (date.getMonth() === month - 1) {
        days.push(new Date(date)); // Pousse une copie de la date
        date.setDate(date.getDate() + 1);
    }

    return days;
};
