import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Widget from "../../components/Widget/Widget";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import {
  getFournisseurs,
  getLegalstatusAll,
  saveCompagny,
  updateCompany,
  deleteFournisseurs,
} from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";

const Fournisseurs = () => {
  const dispatch = useDispatch();

  const [Fournisseurs, setFournisseurs] = useState([]);
  const [legalStatusOptions, setLegalStatusOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFournisseur, setSelectedFournisseur] = useState(null);
  const [modalState, setModalState] = useState({
    add: false,
    edit: false,
    delete: false,
    view: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    legalStatus: "",
    siretNumber: "",
    tvaNumber: "",
    registrationDate: "",
    socialCapital: "",
    logo: "",
    website: "",
    type: ["supplier"],
  });
  const [loading, setLoading] = useState(false); // State for loading

  const columns = [
    { dataField: "name", text: "Nom", sort: true },
    { dataField: "siretNumber", text: "Siret" },
    { dataField: "socialCapital", text: "Social Capital" },
    { dataField: "tvaNumber", text: "TVA Number" },
    {
      dataField: "registrationDate",
      text: "Date d'enregistrement",
      formatter: (cell) => new Date(cell).toLocaleDateString("fr-FR"),
    },
  ];

  const fetchFournisseurs = async () => {
    dispatch(startLoading());
    try {
      const res = await getFournisseurs();
      setFournisseurs(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchLegalStatusOptions = async () => {
    try {
      const res = await getLegalstatusAll();
      setLegalStatusOptions(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFournisseurs();
    fetchLegalStatusOptions();
  }, [dispatch]);

  const toggleModal = (modal) => {
    setModalState((prev) => ({ ...prev, [modal]: !prev[modal] }));
    if (modal !== "edit") resetFormData(); // Reset form data when not editing
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      legalStatus: "",
      siretNumber: "",
      tvaNumber: "",
      registrationDate: "",
      socialCapital: "",
      logo: "",
      website: "",
      type: ["supplier"],
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleAddFournisseur = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await saveCompagny(formData);
      showToast("Fournisseur ajouté avec succès.");
      toggleModal("add");
      fetchFournisseurs();
    } catch (error) {
      const message = error.response?.data?.message || "Erreur lors de l'ajout du Fournisseur.";
      showToast(message, "error");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleEditFournisseur = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const updatedFormData = {
      ...formData,
      type: selectedFournisseur.type || ["supplier"], // Assurez-vous que le type est défini
    }
    try {
      await updateCompany(selectedFournisseur.id, updatedFormData);
      showToast("Fournisseur modifié avec succès.");
      toggleModal("edit");
      fetchFournisseurs();
    } catch (error) {
      const message = error.response?.data?.message || "Erreur lors de la modification du Fournisseur.";
      showToast(message, "error");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleDeleteFournisseurs = async () => {
    const ids = selectedRows.map((row) => row.id);
    try {
      await deleteFournisseurs(ids);
      showToast("Fournisseurs supprimés avec succès.");
      toggleModal("delete");
      setSelectedRows([]);
      fetchFournisseurs();
    } catch (error) {
      showToast("Erreur lors de la suppression des Fournisseurs.", "error");
    }
  };

  const handleRowClick = (row) => {
    setSelectedFournisseur(row); // Set the selected Fournisseur data
    setFormData(row); // Populate the form with the selected Fournisseur data
    toggleModal("edit"); // Open the edit modal
  };

  const handleSelectRow = (row, isSelect) => {
    setSelectedRows((prev) =>
        isSelect ? [...prev, row] : prev.filter((r) => r.id !== row.id)
    );
  };

  return (
      <Row className="mb-4">
        <Col>
          <Widget>
            <div className="button-container mb-1 mt-2 mr-2">
              <Button color="primary" className="mr-2" onClick={() => toggleModal("add")}>
                <i className="eva eva-plus-outline icon" />
              </Button>
              {selectedRows.length === 1 && (
                  <Button color="primary" className="mr-2" onClick={() => handleRowClick(selectedRows[0])}>
                    <i className="eva eva-edit-outline icon" />
                  </Button>
              )}
              {selectedRows.length > 0 && (
                  <Button color="danger" className="mr-2" onClick={() => toggleModal("delete")}>
                    <i className="eva eva-trash-2-outline icon" />
                  </Button>
              )}
            </div>
            <BootstrapTable
                keyField="id"
                data={Fournisseurs}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                selectRow={{
                  mode: "checkbox",
                  onSelect: handleSelectRow,
                  onSelectAll: (isSelect, rows) =>
                      setSelectedRows(isSelect ? rows : []),
                }}
                hover
                striped
                condensed
                rowEvents={{
                  onClick: (e, row) => handleRowClick(row),  // Handle row click for editing
                }}
            />
          </Widget>
        </Col>

        {/* Add Fournisseur Modal */}
        <Modal isOpen={modalState.add} toggle={() => toggleModal("add")}>
          <ModalHeader toggle={() => toggleModal("add")}>
            Ajouter un Fournisseur
          </ModalHeader>
          <Form onSubmit={handleAddFournisseur}>
            <ModalBody>
              {/** Form fields... **/}
              <FormGroup>
                <Label for="name">Nom</Label>
                <Input
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Nom du Fournisseur"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="legalStatus">Statut juridique</Label>
                <Input
                    type="select"
                    id="legalStatus"
                    value={formData.legalStatus}
                    onChange={handleInputChange}
                    required
                >
                  <option value="">Sélectionner...</option>
                  {legalStatusOptions.map(option => (
                      <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="siretNumber">Siret</Label>
                <Input
                    id="siretNumber"
                    value={formData.siretNumber}
                    onChange={handleInputChange}
                    placeholder="Numéro Siret"
                    type="number"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="tvaNumber">TVA</Label>
                <Input
                    id="tvaNumber"
                    value={formData.tvaNumber}
                    onChange={handleInputChange}
                    placeholder="Numéro TVA"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="registrationDate">Date d'enregistrement</Label>
                <Input
                    id="registrationDate"
                    type="date"
                    value={formData.registrationDate}
                    onChange={handleInputChange}
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="socialCapital">Capital Social</Label>
                <Input
                    id="socialCapital"
                    value={formData.socialCapital}
                    onChange={handleInputChange}
                    placeholder="Capital Social"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="logo">Logo (URL)</Label>
                <Input
                    id="logo"
                    value={formData.logo}
                    onChange={handleInputChange}
                    placeholder="URL du logo"
                />
              </FormGroup>
              <FormGroup>
                <Label for="website">Site Web</Label>
                <Input
                    id="website"
                    value={formData.website}
                    onChange={handleInputChange}
                    placeholder="Site Web"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? "Chargement..." : "Ajouter"}
              </Button>
              <Button color="secondary" onClick={() => toggleModal("add")}>
                Annuler
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {/* Edit Fournisseur Modal */}
        <Modal isOpen={modalState.edit} toggle={() => toggleModal("edit")}>
          <ModalHeader toggle={() => toggleModal("edit")}>
            Modifier le Fournisseur
          </ModalHeader>
          <Form onSubmit={handleEditFournisseur}>
            <ModalBody>
              {/* Same fields as in Add Modal */}
              <FormGroup>
                <Label for="name">Nom</Label>
                <Input
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Nom du Fournisseur"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="legalStatus">Statut juridique</Label>
                <Input
                    type="select"
                    id="legalStatus"
                    value={formData.legalStatus}
                    onChange={handleInputChange}
                    required
                >
                  <option value="">Sélectionner...</option>
                  {legalStatusOptions.map(option => (
                      <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="siretNumber">Siret</Label>
                <Input
                    id="siretNumber"
                    value={formData.siretNumber}
                    onChange={handleInputChange}
                    placeholder="Numéro Siret"
                    type="number"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="tvaNumber">TVA</Label>
                <Input
                    id="tvaNumber"
                    value={formData.tvaNumber}
                    onChange={handleInputChange}
                    placeholder="Numéro TVA"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="registrationDate">Date d'enregistrement</Label>
                <Input
                    id="registrationDate"
                    type="date"
                    value={formData.registrationDate}
                    onChange={handleInputChange}
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="socialCapital">Capital Social</Label>
                <Input
                    id="socialCapital"
                    value={formData.socialCapital}
                    onChange={handleInputChange}
                    placeholder="Capital Social"
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="logo">Logo (URL)</Label>
                <Input
                    id="logo"
                    value={formData.logo}
                    onChange={handleInputChange}
                    placeholder="URL du logo"
                />
              </FormGroup>
              <FormGroup>
                <Label for="website">Site Web</Label>
                <Input
                    id="website"
                    value={formData.website}
                    onChange={handleInputChange}
                    placeholder="Site Web"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? "Chargement..." : "Modifier"}
              </Button>
              <Button color="secondary" onClick={() => toggleModal("edit")}>
                Annuler
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {/* Delete Fournisseur Modal */}
        <Modal isOpen={modalState.delete} toggle={() => toggleModal("delete")}>
          <ModalHeader toggle={() => toggleModal("delete")}>
            Supprimer des Fournisseurs
          </ModalHeader>
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer {selectedRows.length} Fournisseurs ?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDeleteFournisseurs}>
              Supprimer
            </Button>
            <Button color="secondary" onClick={() => toggleModal("delete")}>
              Annuler
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
  );
};

export default Fournisseurs;
