import React, { useState } from "react";
import { Switch, Route } from "react-router";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./pages/login/Login";
import { ToastContainer } from "react-toastify";
import "./styles/app.scss";
import Organizations from "./pages/organizations/OrganizationsPage";
import LoadingPage from "./components/Spinner/LoadingPage";
import PrivateRoute from "./components/PrivateRoute";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Home from "./components/Layout/Home";
import { UserProvider } from "./UserContext";

const App = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <UserProvider>
            <div>
                <ToastContainer />
                <LoadingPage isLoading={loading} />
                <HashRouter>
                    <Switch>
                        <PrivateRoute
                            path="/home"
                            setLoading={setLoading}
                            dispatch={props.dispatch}
                            component={Home}
                        />
                        <PrivateRoute
                            path="/organizations"
                            dispatch={props.dispatch}
                            component={Organizations}
                        />
                        <Route path="/login" exact component={Login} />
                        <Redirect to="/home" />
                    </Switch>
                </HashRouter>
            </div>
        </UserProvider>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
