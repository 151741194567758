
import Axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_URL;;

export function login(email, password) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const data = {
    email: email,
    password: password
  };
  console.log("URL Used " + API_BASE_URL);
  return Axios.post(API_BASE_URL + "/security/authenticate", data, { headers: headers })
}

export function changeTemporaryPassword({ email, tempoPassword, new_password }) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const data = {
    email: email,
    tempoPassword: tempoPassword,
    new_password: new_password
  };
  return Axios.post(API_BASE_URL + "/security/changeTemporaryPassword", data, { headers: headers });
}

export function getOrganizations() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  // Make the GET request using fetch
  return Axios.get(API_BASE_URL + "/organizations", { headers: headers })
}

export function getEmployees() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/employees", { headers: headers })
}
export function getEmployeeById(id) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/employees/" + id, { headers: headers })
}
export function saveEmployee(data) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.post(API_BASE_URL + "/employees", data, { headers: headers })
}

export function deleteEmployees(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/employees/delete", { data: selectedIds, headers: headers })
}
export function updateEmployee(id, data) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.put(`${API_BASE_URL}/employees/${id}`, data, { headers: headers });
}
export function updateEmployeeImage(id, imageFile) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  const formData = new FormData();
  formData.append('image', imageFile);

  return Axios.put(`${API_BASE_URL}/employees/ImageEmployee/${id}`, formData, { headers: headers });
}
export function getEmployeeImage(id) {
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    // Enlève l'en-tête Accept ou utilise '*/*'
    'Accept': '*/*'
  };

  return Axios.get(`${API_BASE_URL}/employees/ImageSimulation/${id}`, {
    headers: headers,
    responseType: 'blob'  // Nous demandons toujours un blob
  });
}
export function createUserForEmployee(id) {
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  return Axios.get(`${API_BASE_URL}/employees/CreateUserForEmplyee/${id}`, { headers: headers });
}
export function changePasswordForEmployee(id) {
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  return Axios.get(`${API_BASE_URL}/employees/ChangePswUserForEmplyee/${id}`, { headers: headers });
}
export function getCompagnyTypes() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  // Make the GET request using fetch
  return Axios.get(API_BASE_URL + "/compagnyTypes", { headers: headers })
}

export function getFournisseurs() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/compagny/type=supplier", { headers: headers })
}

export function getClients() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/compagny/type=customer", { headers: headers })
}
export function getLegalstatusAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/legalstatus", { headers: headers })
}

export function updateCompany(id, companyData) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")  // Add authorization if needed
  };
  console.log(companyData)
  return Axios.put(`${API_BASE_URL}/compagny/${id}`, companyData, { headers: headers });
}


export function saveLegalStatus(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/legalstatus", data, { headers: headers })
}
export function updateLegalStatus(id, LegalStatusData) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")  // Add authorization if needed
  };
  return Axios.put(`${API_BASE_URL}/legalstatus/${id}`, LegalStatusData, { headers: headers });
}

export function deleteLegalStatus(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/legalstatus/delete", { data: selectedIds, headers: headers })
}

export function deleteFournisseurs(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/compagny/delete", { data: selectedIds, headers: headers })
}

export function saveFournisseur(
  nom,
  siretNumber,
  legalStatus,
  socialCapital,
  tvaNumber,
  registrationDate,
  website,
  type) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    name: nom,
    siretNumber: siretNumber,
    tvaNumber: tvaNumber,
    registrationDate: registrationDate,
    socialCapital: socialCapital,
    website: website,
    type: type,
    legalStatus: legalStatus
  };
  return Axios.post(API_BASE_URL + "/compagny", data, { headers: headers })
}
export function saveCompagny(data) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  console.log(data);
  return Axios.post(API_BASE_URL + "/compagny", data, { headers: headers })
}


export function saveOrganization(
  name,
  siretNumber,
  mobileNumber,
  phoneNumber,
  legalStatus,
  tvaNumber,
  registrationDate,
  website,
  addressLine1,
  addressLine2,
  zipcode,
  city,
  country,
  email
) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    name: name,
    siretNumber: siretNumber,
    mobileNumber: mobileNumber,
    phoneNumber: phoneNumber,
    legalStatus: legalStatus,
    tvaNumber: tvaNumber,
    registrationDate: registrationDate,
    website: website,
    adressLine1: addressLine1,
    adressLine2: addressLine2,
    zipcode: zipcode,
    city: city,
    country: country,
    email: email
  };

  return Axios.post(API_BASE_URL + "/organizations", data, { headers: headers })
}

export function getPaymentTypeAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/paymenttype", { headers: headers })
}
export function savePaymentType(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/paymenttype", data, { headers: headers })
}
export function deletePaymentType(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/paymenttype/delete", { data: selectedIds, headers: headers })
}

export function getAdressTypeAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/addresstype", { headers: headers })
}
export function saveAdressType(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/addresstype", data, { headers: headers })
}
export function deleteAdressType(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/addresstype/delete", { data: selectedIds, headers: headers })
}

export function getSimulationStatusAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulationstatus", { headers: headers })
}
export function saveSimulationStatus(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/simulationstatus", data, { headers: headers })
}
export function deleteSimulationStatus(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/simulationstatus/delete", { data: selectedIds, headers: headers })
}
export function deleteOrganization(selectedId) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/organizations/" + selectedId, { headers: headers })
}

export function getconfigurationSimValuesAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/configurationsimvalue", { headers: headers })
}
export function saveconfigurationSimValues(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/configurationsimvalue", data, { headers: headers })
}
export function deleteconfigurationSimValues(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/configurationsimvalue/delete", { data: selectedIds, headers: headers })
}
export function getConfigurationsFraisKiloAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/coefficientkilo", { headers: headers })
}
export function saveConfigurationsFraisKilo(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/coefficientkilo", data, { headers: headers })
}
export function deleteConfigurationsFraisKilo(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/coefficientkilo/delete", { data: selectedIds, headers: headers })
}
export function calculateFraisKilo(distance, chevaux, nbreOfDays) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/coefficientkilo/calculatePSW?chevaux=" + chevaux + "&distance=" + distance + "&nbreOfDays=" + nbreOfDays, { headers: headers })
}

export function getSimulation(dataToSend) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.post(API_BASE_URL + "/simulation/calculate", dataToSend, { headers: headers })
}

export function calculateFraisTtv(FratTTVinputs) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.post(API_BASE_URL + "/coefficientkilo/calculateTTV", JSON.stringify(remplacerVidesParZero(FratTTVinputs)), { headers: headers })
}

export function saveSimulation({ data, inputData }) {

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const Apidata = {
    status: data.status,
    dailyPrice: data.dailyPrice,
    nbreOfDays: data.nbreOfDays,
    savingAmount: data.savingAmount,
    id_consultant: inputData.idEmployee,
    chiffreAffaire: data.chiffreAffaire,
    fraisDeGestion: data.fraisDeGestion,
    panierRepas: data.panierRepas,
    fraisAnnexe: data.fraisAnnexe,
    ik: data.ik,
    ndf: data.ndf,
    fraisprofessionnels: data.fraisprofessionnels,
    primeDapportDaffaire: data.primeDapportDaffaire,
    indeminiteCongePaye: data.indeminiteCongePaye,
    salaireBrute: data.salaireBrute,
    salaireNetImposable: data.salaireNetImposable,
    salaireOptimiseNet: data.salaireOptimiseNet,
    reserve: data.salaireOptimiseNet,
    reste: data.reste,
    compteCourant: data.compteCourant,
    firstName: inputData.employeeFirstName,
    lastName: inputData.employeeLastName,
    salaireBrutDebase: data.salaireBrutDebase,
    email: inputData.email,
    duty: inputData.employeeJob,
    pfg: inputData.pfg}
  return Axios.post(API_BASE_URL + "/simulation/save", Apidata, { headers: headers })
}

export function updateSimulation(SimIdToUpdate) {

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  return Axios.put(API_BASE_URL + "/simulation/"+SimIdToUpdate.id, SimIdToUpdate, { headers: headers })
}

export function getSimulations( type){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/simulations?type="+type, { headers: headers })
}

export function getSimulationById(id) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/simulation?id="+id, { headers: headers })
}

export function setEnModeNegotiation( id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/PasserEnNegotiation?id="+id, { headers: headers }) 
}

export function setEnModeSignature(simulationId , simulation){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const updatedSimulation = {
    ...simulation,  // Inclure les propriétés existantes de simulation
    simulationId: simulationId  // Ajouter simulationId
  };
  return Axios.post(API_BASE_URL + "/simulation/PasserEnSignature",updatedSimulation, { headers: headers })
}

export function setEnModeAnnulation( id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/PasserContratEnResiliation?id="+id, {responseType: 'arraybuffer',headers: headers}) 
}

export function getImageSimulation( id){
  const headers = {
    'Content-Type': 'IMAGE_JPEG',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/ImageSimulation?id="+id, { headers: headers })
}

function remplacerVidesParZero(objet) {
  const nouvelObjet = {};
  for (const prop in objet) {
    if (objet.hasOwnProperty(prop)) {
      nouvelObjet[prop] = objet[prop] === "" ? 0 : parseFloat(objet[prop]);
    }
  }
  return nouvelObjet;
}

export function getEMployeedIDSimulationByID(id) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/simulationEmplyeeId?id="+id, {headers: headers})
}

export function getContracts( ){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/consultancyService", { headers: headers })
}
export function getContractById(id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/consultancyService/"+id, { headers: headers })
}

export function deleteContract(id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/consultancyService"+id, { headers: headers })
}

export function loadPdfByIdApi(id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + `/consultancyService/pdf/${id}`, { headers: headers, responseType: 'blob' })
}

export const uploadPdfApi = async (file, type, idContrat) => {
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  formData.append('consultancyServiceid', idContrat);

  return Axios.post(`${API_BASE_URL}/consultancyService/upload-pdf`, formData, { headers });
};
export function loadPdfClientByIdByTypeApi(id, type){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(`${API_BASE_URL}/consultancyService/download-pdf/${id}/${type}`, { headers: headers, responseType: 'blob' })
}

export function getCurrentInfo(){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/security/user", { headers: headers })
}
export function getCraRecords(missionId, month, year) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  const url = `${API_BASE_URL}/cra/records?missionId=${missionId}&month=${month}&year=${year}`;

  return Axios.get(url, { headers: headers });
}
export function getMissionById(missionId) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(`${API_BASE_URL}/cra/mission/${missionId}`, { headers: headers });
}

export function saveCra(cra){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
 return Axios.post(API_BASE_URL + "/cra/save",cra, { headers: headers })
}

//craManagement
export function getCraManger(month,year) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(`${API_BASE_URL}/craManager/records?month=${month}&year=${year}`, { headers: headers });
}


export function UpdateCraManger(craData){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.post(API_BASE_URL + "/craManager/recordStatus",craData, { headers: headers })
}



