import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import hasToken from "../../services/authService";
import loginImage from "../../assets/loginImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import {login, changeTemporaryPassword, getCurrentInfo} from '../../services/configService';
import "./login.scss";
import { decodeJWT } from "../../services/securityService";
import showToast from '../../components/NotificationToast/toastUtils'
import {startLoading, stopLoading} from "../../actions/loading";

const Login = (props) => {
  const [state, setState] = useState({
    email: 'utilisateur@n2e-it.com',
    password: 'password',
    errorMessage: '',
    isAuthenticated: false,
    showModal: false,
    tempoPassword: '',
    newPassword: '',
    confirmPassword: '' // Nouveau champ pour la confirmation du mot de passe
  });

  const doLogin = async (e) => {
    e.preventDefault();
    if (state.email.length > 0 && state.password.length > 0) {
      startLoading();
      try {
        const response = await login(state.email, state.password); // Ajout d'await ici
        localStorage.setItem('token', response.data.id_token);
        const role = decodeJWT(response.data.id_token).role;

        // Redirection en fonction du rôle
        if (role === 'admin' || role === 'manager') {
          props.history.push('/home/prospections');
        } else if (role === 'consultant') {
          props.history.push('/home/CRA');
        }
      } catch (error) {
        // Gestion des erreurs
        if (error.response && error.response.status && error.response.status == 333) {
          setState({ ...state, showModal: true, errorMessage: '' });
        } else if (error.response && error.response.status === 401) {
          setState({ ...state, errorMessage: "L'adresse e-mail ou le mot de passe est incorrect. Veuillez réessayer." });
        } else {
          setState({ ...state, errorMessage: "Une erreur s'est produite. Veuillez réessayer." });
        }
      } finally {
        stopLoading(); // Assurez-vous que stopLoading est appelé dans le bloc finally
      }
    } else {
      setState({ ...state, errorMessage: 'Veuillez remplir tous les champs.' });
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    startLoading();
    if (!state.email || !state.tempoPassword || !state.newPassword || !state.confirmPassword) {
      showToast("Tous les champs doivent être remplis.", "error");
      return;
    }

    if (state.newPassword !== state.confirmPassword) {
      showToast("Les deux mots de passe saisis ne sont pas identiques."  , "error");
    } else {
      changeTemporaryPassword({
        email: state.email, // inclure l'email dans la requête
        tempoPassword: state.tempoPassword, // utiliser le tempoPassword saisi dans le modal
        new_password: state.newPassword
      })
          .then(() => {
            setState({ ...state, showModal: false, errorMessage: '' });
            showToast("Mot de passe changé avec succès ! Veuillez vous reconnecter." );
          })
          .catch(err => {
            showToast("Erreur lors du changement de mot de passe : " + err.message , "error");
          });
    }
    stopLoading()
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { from } = props.location.state || { from: { pathname: '/organizations' } };
  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
    return <Redirect to={from} />;
  }

  return (
      <div className="auth-page">
        <Container className="col-12">
          <Row className="d-flex align-items-center">
            <Col xs={12} lg={6} className="left-column">
              <Widget className="widget-auth widget-p-lg">
                <div className="d-flex align-items-center justify-content-between py-3">
                  <p className="auth-header mb-0">Connexion</p>
                  <div className="logo-block">
                    <SofiaLogo />
                    <p className="mb-0">CRM</p>
                  </div>
                </div>
                <div className="auth-info my-2">
                  <p>Bienvenue sur le CRM <b>N2E-IT</b> CONSULTING. Connectez-vous pour accéder à votre espace personnel.</p>
                </div>
                <form onSubmit={(event) => doLogin(event)}>
                  <FormGroup className="my-3">
                    <FormText>Adresse e-mail</FormText>
                    <Input
                        id="email"
                        className="input-transparent pl-3"
                        value={state.email}
                        onChange={(event) => changeCreds(event)}
                        type="text"
                        required
                        name="email"
                        placeholder="Email"
                    />
                  </FormGroup>
                  <FormGroup className="my-3">
                    <div className="d-flex justify-content-between">
                      <FormText>Mot de passe</FormText>
                      <Link to="/error">Mot de passe oublié ?</Link>
                    </div>
                    <Input
                        id="password"
                        className="input-transparent pl-3"
                        value={state.password}
                        onChange={(event) => changeCreds(event)}
                        type="password"
                        required
                        name="password"
                        placeholder="Mot de passe"
                    />
                  </FormGroup>
                  {state.errorMessage && <span className="error-login-message">{state.errorMessage}</span>}
                  <div className="bg-widget d-flex justify-content-center">
                    <Button className="rounded-pill my-3" type="submit" color="secondary-red">Se connecter</Button>
                  </div>
                </form>
              </Widget>
            </Col>
            <Col xs={0} lg={6} className="right-column">
              <div>
                <img src={loginImage} alt="Page de connexion" />
              </div>
            </Col>
          </Row>
        </Container>

        {/* Modal pour changer le mot de passe temporaire */}
        <Modal isOpen={state.showModal}>
          <ModalHeader>Changer votre mot de passe</ModalHeader>
          <ModalBody>
            Le mot de passe que vous avez entré est temporaire. Vous devez le remplacer par votre propre mot de passe.
            <FormGroup>
              <FormText>Adresse e-mail</FormText>
              <Input
                  type="email"
                  name="email"
                  placeholder="Entrez votre adresse e-mail"
                  value={state.email}
                  onChange={(event) => changeCreds(event)}
              />
            </FormGroup>
            <FormGroup>
              <FormText>Mot de passe temporaire</FormText>
              <Input
                  type="password"
                  name="tempoPassword"
                  placeholder="Entrez votre mot de passe temporaire"
                  value={state.tempoPassword}
                  onChange={(event) => changeCreds(event)}
              />
            </FormGroup>
            <FormGroup>
              <FormText>Nouveau mot de passe</FormText>
              <Input
                  type="password"
                  name="newPassword"
                  placeholder="Entrez votre nouveau mot de passe"
                  value={state.newPassword}
                  onChange={(event) => changeCreds(event)}
              />
            </FormGroup>
            <FormGroup>
              <FormText>Confirmer le nouveau mot de passe</FormText>
              <Input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirmez votre nouveau mot de passe"
                  value={state.confirmPassword}
                  onChange={(event) => changeCreds(event)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleChangePassword}>Changer le mot de passe</Button>
            <Button color="secondary" onClick={() => setState({ ...state, showModal: false })}>Annuler</Button>
          </ModalFooter>
        </Modal>

        <Footer />
      </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Login;
