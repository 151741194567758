import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, FormGroup, FormFeedback } from 'reactstrap';
import "./ContractCreationModal.scss";
import Select from 'react-select';
import ProgressSteps from '../ProgressSteps/ProgressSteps';
import {
  getEmployees,
  getEmployeeById,
  getEMployeedIDSimulationByID,
  getClients
} from "../../services/configService";
import InputCustom from '../InputCustom/InputCustom'
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const SOCIAL_SECURITY_REGEX = /^\d{15}$/;

const ContractCreationModal = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    socialSecurityNumber: '',
    tvaNumber: '',
    legalStatus: '',
    siretNumber: '',
    beginDate: '',
    endDate: '',
    compagny_id: '',
    beginDateConsultannt:''

  });
  const [errors, setErrors] = useState({});
  const [employeesLocal, setEmployeesLocal] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchData();
  }, [props.simulationId]);

  const fetchData = async () => {
    try {
      const res = await getEmployees();
      setEmployeesLocal(res.data);
      if(props.simulationId !== null){
      const res2 = await getEMployeedIDSimulationByID(props.simulationId)
        fetchEmployeeDetails(res2.data)
      }
    } catch (error) {
      console.error(error);
    }
    try {
      const res = await getClients();
      setClients(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeeDetails = async (employeeId) => {
    try {
      const res = await getEmployeeById(employeeId);
      setFormData({
        ...formData,
        firstName: res.data.firstName || '',
        lastName: res.data.lastName || '',
        birthDate: res.data.birthDate || '',
        email: res.data.email || '',
        socialSecurityNumber: res.data.socialSecurityNumber || '',
        employee_id: employeeId
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'employé:', error);
    }
  };

  const handlePrev = () => {
    setActiveStep(prevStep => Math.max(1, prevStep - 1));
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep(prevStep => Math.min(3, prevStep + 1));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleEmployeeSelect = async (employeeId) => {
    setFormData(prevFormData => ({ ...prevFormData, employee_id: employeeId }));
    await fetchEmployeeDetails(employeeId);
  };

  const handleClientSelect = async (ClientId) => {
    setFormData({ ...formData, compagny_id: ClientId });
  };

  const validateStep = (step) => {
    let validationErrors = {};
    let isValid = true;

    if (step === 1) {
      isValid = validateField('firstName', formData.firstName, validationErrors, null, true) &&
          validateField('lastName', formData.lastName, validationErrors, null, true) &&
          validateField('birthDate', formData.birthDate, validationErrors, null, true) &&
          validateField('email', formData.email, validationErrors, EMAIL_REGEX, true) &&
          validateField('socialSecurityNumber', formData.socialSecurityNumber, validationErrors, SOCIAL_SECURITY_REGEX, true);
      if (!formData.employee_id) {
        validationErrors.employee_id = 'Ce champ est obligatoire';
        isValid = false;
      }
    } else if (step === 2) {
      isValid = validateField('compagny_id', formData.compagny_id, validationErrors, null, true);
    } else if (step === 3) {
      isValid = validateField('beginDate', formData.beginDate, validationErrors, null, true) &&
        validateField('endDate', formData.endDate, validationErrors, null, true) &&
          validateField('beginDateConsultannt',formData.beginDateConsultannt,validationErrors, null, true)
    ;

    }

    setErrors(validationErrors);
    return isValid;
  };

  const validateField = (id, value, validationErrors, regex, mandatory = false) => {
    const stringValue = String(value).trim();
    if (mandatory && !stringValue) {
      validationErrors[id] = 'Ce champ est obligatoire';
      return false;
    }
    if (regex && !regex.test(stringValue)) {
      validationErrors[id] = 'Format invalide';
      return false;
    }
    return true;
  };

  const handleValidate = () => {
    if (validateStep(3)) {
      props.handleValidate(formData);
    }
  };
  return (
    <Modal isOpen={props.modalContractOpen} toggle={props.toggleModalContract} className="form-input">
      <ModalHeader toggle={props.toggleModalContract}>Création du contrat</ModalHeader>
      <ModalBody>
        <ProgressSteps activeStep={activeStep} />
        {activeStep === 1 && (
          <Form>
            <div className="form-row">
              <div className="col-md-6 mb-1">
                <FormGroup>
                  <Label for="employee">Employé</Label>
                  <Select
                      name="employee_id"
                      placeholder="Rechercher un employé"
                      options={employeesLocal.map(emp => ({ value: emp.id, label: `${emp.firstName} ${emp.lastName}` }))}
                      onChange={(selectedOption) => handleEmployeeSelect(selectedOption.value)}
                      value={employeesLocal.find(emp => emp.id === formData.employee_id) ? { value: employeesLocal.find(emp => emp.id === formData.employee_id).id, label: `${employeesLocal.find(emp => emp.id === formData.employee_id).firstName} ${employeesLocal.find(emp => emp.id === formData.employee_id).lastName}` } : null}
                  />
                  <FormFeedback>{errors.employee_id}</FormFeedback>
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <InputCustom
                label="Prénom"
                type="text"
                id="firstName"
                placeholder="Prénom"
                value={formData.firstName}
                onChange={handleChange}
                error={errors.firstName}
                invalid={!!errors.firstName}
                className="col-md-6 mb-1"
              />
              <InputCustom
                label="Nom"
                type="text"
                id="lastName"
                placeholder="Nom"
                value={formData.lastName}
                onChange={handleChange}
                error={errors.lastName}
                invalid={!!errors.lastName}
                className="col-md-6 mb-1"
              />
            </div>
            <div className="form-row">
              <InputCustom
                label="Date de naissance"
                type="date"
                id="birthDate"
                placeholder="Date de naissance"
                value={formatDateForInput(formData.birthDate)}
                onChange={handleChange}
                error={errors.birthDate}
                invalid={!!errors.birthDate}
                className="col-md-6 mb-1"
              />
              <InputCustom
                label="Email"
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
                invalid={!!errors.email}
                className="col-md-6 mb-1"
              />
            </div>
            <div className="form-row">
              <InputCustom
                label="Numéro de sécurité sociale"
                type="number"
                id="socialSecurityNumber"
                placeholder="Numéro de sécurité sociale - format 15 chiffres"
                value={formData.socialSecurityNumber}
                invalid={!!errors.socialSecurityNumber}
                onChange={handleChange}
                error={errors.socialSecurityNumber}
                className="col-md-6 mb-1"
              />
            </div>
          </Form>
        )}
        {activeStep === 2 && (
          <Form>
            <div className="form-row">
              <div className="col-md-6 mb-5">
                <FormGroup>
                  <Label for="client">Liste des clients</Label>
                  <Select
                    name="compagny_id"
                    className="mb-5"
                    options={clients.map(client => ({ value: client.id, label: `${client.name} | Siret :${client.siretNumber}` }))}
                    onChange={(selectedOption) => handleClientSelect(selectedOption.value)}
                    value={clients.filter(client => client.id === formData.compagny_id).map(client => ({ value: client.id, label: `${client.name} | Siret :${client.siretNumber}` }))}
                  />
                  <FormFeedback>{errors.client}</FormFeedback>
                </FormGroup>
              </div>
            </div>
          </Form>
        )}
        {activeStep === 3 && (
            <Form>
              <h4>Contrat Client</h4>
              <div className="form-row">

                <InputCustom
                    label="Date de début"
                    type="date"
                    id="beginDate"
                    placeholder="Date de début"
                    value={formData.beginDate}
                    onChange={handleChange}
                    error={errors.beginDate}
                    invalid={!!errors.beginDate}
                    className="col-md-6 mb-1"
                />
                <InputCustom
                    label="Date de fin"
                    type="date"
                    id="endDate"
                    placeholder="Date de fin"
                    value={formData.endDate}
                    onChange={handleChange}
                    error={errors.endDate}
                    invalid={!!errors.endDate}
                    className="col-md-6 mb-1"
                />
              </div>
              <h4>Contrat Consultant</h4>
              <div className="form-row">
                <InputCustom
                    label="Date de début de contrat"
                    type="date"
                    id="beginDateConsultannt"  // Make sure this ID matches the field in formData
                    placeholder="Date de début de contrat"
                    value={formData.beginDateConsultannt}
                    onChange={handleChange}
                    error={errors.beginDateConsultannt}
                    invalid={!!errors.beginDateConsultannt}
                    className="col-md-6 mb-1"
                />
              </div>
            </Form>
          )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handlePrev} disabled={activeStep === 1}>Précédent</Button>
        {activeStep < 3 && <Button color="primary" onClick={handleNext}>Suivant</Button>}
        {activeStep === 3 && <Button color="primary" onClick={handleValidate}>Valider</Button>}
        <Button color="secondary" onClick={props.toggleModalContract}>Annuler</Button>
      </ModalFooter>
    </Modal>
  );
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';

  // Convertit la chaîne de date en un objet Date
  const date = new Date(dateString);

  // Obtient l'année, le mois et le jour en format YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Retourne la date formatée en string YYYY-MM-DD
  return `${year}-${month}-${day}`;
};
export default ContractCreationModal;
