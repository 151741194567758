// src/utils/pdfUtils.js

import { startLoading, stopLoading } from "../actions/loading";
import showToast from "../components/NotificationToast/toastUtils";
import {
    loadPdfClientByIdByTypeApi,
    uploadPdfApi
} from "../services/configService";

export const loadPdf = async (id, type, label) => {
    console.log(id, type, label)
    try {
        const res = await loadPdfClientByIdByTypeApi(id,type);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `_${label}.pdf`; // Nom du fichier PDF
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        showToast('Le fichier PDF est téléchargé.');
    } catch (error) {
        showToast('Erreur lors du téléchargement du fichier PDF.', "error");
    }
};
export const uploadPdfClient = async (file, type, idContrat) => {
    try {
        const response = await uploadPdfApi(file, type, idContrat);

        // Si vous souhaitez faire quelque chose avec la réponse, vous pouvez le faire ici.
        console.log('Le fichier PDF a été téléchargé avec succès.', response.data);
    } catch (error) {
        console.error('Erreur lors du téléchargement du fichier PDF.', error);
    }
};