import React, { createContext, useState } from "react";

// Création du contexte
export const UserContext = createContext();

// Création d'un provider pour le contexte utilisateur
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    return (
        <UserContext.Provider value={{ user, setUser, token, setToken }}>
            {children}
        </UserContext.Provider>
    );
};
