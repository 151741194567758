import {login} from '../services/configService'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem('token');
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    dispatch(receiveLogin());
    if (creds.email.length > 0 && creds.password.length > 0) {
      // Utiliser la fonction login pour authentifier l'utilisateur
      login(creds.email, creds.password)
        .then(response => {
          // Authentification réussie
          localStorage.setItem('token', response.data.id_token);
          const role = decodeJWT(response.data.id_token).role
          if (role === 'admin' || role === 'manager') {
            creds.props.history.push('/organizations');
          } else if (role === 'consultant') {
            creds.props.history.push('/home/CRA');
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Si l'erreur est une réponse 401 (Non autorisé)
            dispatch(loginError('L\'adresse e-mail ou le mot de passe est incorrect. Veuillez réessayer.'));
          } else {
            // Une autre erreur s'est produite
            dispatch(loginError('Une erreur s\'est produite. Veuillez réessayer.'));
          }
        });
    } else {
      dispatch(loginError('Quelque chose s\'est mal passé. Veuillez réessayer.'));
    }
  };
}

export function decodeJWT(token) {
  // Récupérer la partie payload du token JWT
  const base64Url = token.split('.')[1];
  // Remplacer les caractères spécifiques de Base64URL par ceux de Base64 standard
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  // Décoder la chaîne Base64
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  // Convertir le payload en objet JavaScript
  const decoded = JSON.parse(jsonPayload);

  // Extraire les rôles
  const roles = decoded.auth;
  let userRole = '';

  // Vérifier les rôles et retourner celui qui correspond
  if (roles.includes('ADMINS_APPLICATIFS')) {
    userRole = 'admin';
  } else if (roles.includes('MANAGER_APPLICATIFS')) {
    userRole = 'manager';
  } else if (roles.includes('CONSULTANTS_APPLICATIFS')) {
    userRole = 'consultant';
  } else {
    userRole = 'unauthorized';
  }

  // Retourner l'objet avec les données du token et le rôle
  return {
    ...decoded,
    role: userRole // Ajouter le rôle dans l'objet retourné
  };
}