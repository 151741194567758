import React, { useEffect, useState } from "react";
import {
    Button, Input, Modal, ModalHeader, ModalBody,
    Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon
} from "reactstrap";
import CustomAccordion from "../Accordion/CustomAccordion";
import RechartsPieChart from "../../pages/uielements/charts/components/RechartsPieChart";
import MultiOptionButton from "../MultiOptionButton/MultiOptionButton";
import CustomAccordion2 from "../Accordion/CustomAccordion2";
import {
    getconfigurationSimValuesAll,
    getEmployeeById,
    getEmployees,
    getSimulation,
    saveSimulation,
    updateSimulation
} from "../../services/configService";
import { formatNmbr } from '../../services/OutilService'
import { startLoading, stopLoading } from "../../actions/loading";
import { useDispatch } from "react-redux";
import showToast from "../NotificationToast/toastUtils";


const ModalAddProspection = (
    props
) => {

    const [data, setData] = useState({
        dailyPrice: "0",
        nbreOfDays: "0",
        savingAmount: "0",
        chiffreAffaire: "0",
        fraisDeGestion: "0",
        panierRepas: "0",
        fraisAnnexe: "0",
        ik: "0",
        ndf: "0",
        fraisprofessionnels: "0",
        primeDapportDaffaire: "0",
        indemniteCongePaye: "0",
        salaireBrute: "0",
        salaireNetImposable: "0",
        salaireOptimiseNet: "0",
        reserve: "0",
        reste: "0",
        compteCourant: "0",
        salaireBrutDebase: "0"
    });


    const [PieValues, setPieValues] = useState({
        v1: 0,
        v2: 0,
        v3: 0
    })
    const [employees, setEmployees] = useState([]);
    const [inputData, setInputData] = useState({
        dailyPrice: "",
        nbrJours: "",
        savingAmount: 0,
        fraisKilom: 0,
        email: "",
        idEmployee: 0,
        employeeFirstName: "",
        employeeLastName: "",
        employeeJob: "",
        ndf: 0,
        pfg:""
    });
    const dispatch = useDispatch();

    const handleOptionClick = async (option) => {
        if (data.chiffreAffaire === '0') {
            showToast('Veuillez calculer la simulation avant l\'enregistrer', "error");
            return;
        }
        if (option === "Option 1") {
            dispatch(startLoading());
            try {
                if (props.SimIdToUpdate === 0) {
                await saveSimulation({ data, inputData }); }
                else {
                    await updateSimulation(mergeData(props.SimIdToUpdate , data, inputData) );
                }
                showToast('La simulation a été enregistré avec succès.');
               setTimeout(() => {
                    window.location.reload(); 
                }, 2000);
            } catch (error) {
               showToast('La simulation n\'a pas pu être enregistré.' , "error");
            } finally {
                dispatch(stopLoading());
            }
        }
    }

    useEffect(() => {
        fetchData();

    }, []);

    const fetchData = async () => {
        try {
            const res = await getEmployees();
            setEmployees(res.data);
        } catch (error) {
            showToast(error , "error");
        }
    };
    const fetchFraisdeService = async () => {
        try {
            const res = await getconfigurationSimValuesAll();
            const valueData = res.data.find(item => item.code === "PDFDG").value;
            return valueData; // Return valueData
        } catch (error) {
            console.log(error);
            throw error; // Re-throw the error to be handled by the caller
        }
    };

    const handleClickCalculate = async () => {
        const { employeeLastName, employeeFirstName , dailyPrice, nbrJours } = inputData;
        if (!employeeLastName || !employeeFirstName || !dailyPrice || !nbrJours) {
            showToast('Veuillez remplir tous les champs obligatoires ', "error");
            return;
        }

        dispatch(startLoading());
        try {
            const res = await getSimulation(inputData)
            setData(res.data)
            setPieValues({
                v1: res.data.salaireOptimiseNet,
                v2: res.data.fraisDeGestion,
                v3: res.data.fraisAnnexe
            });
        } catch (error) {
            showToast('Une erreur s\'est produite : ' , "error");
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleSelectConsultant = async (e) => {
        dispatch(startLoading());
        try {
            const res = await getEmployeeById(e);
            const { firstName, lastName, email, duty, id } = res.data;
            setInputData(prevData => ({
                ...prevData,
                employeeFirstName: firstName,
                employeeLastName: lastName,
                email: email,
                employeeJob: duty,
                idEmployee: id
            }));
        } catch (error) {
            console.error('Une erreur s\'est produite : ', error);
        } finally {
            dispatch(stopLoading());
        }
    };
    const updateInputData = (key, value) => {
        setInputData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(async () => {
        if (props.modalOpen) {
            if (props.SimIdToUpdate === 0) {
                const value = await fetchFraisdeService();
                setData({
                    dailyPrice: "0",
                    nbreOfDays: "0",
                    savingAmount: "0",
                    chiffreAffaire: "0",
                    fraisDeGestion: "0",
                    panierRepas: "0",
                    fraisAnnexe: "0",
                    ik: "0",
                    ndf: "0",
                    fraisprofessionnels: "0",
                    primeDapportDaffaire: "0",
                    indemniteCongePaye: "0",
                    salaireBrute: "0",
                    salaireNetImposable: "0",
                    salaireOptimiseNet: "0",
                    reserve: "0",
                    reste: "0",
                    compteCourant: "0",
                    salaireBrutDebase: "0"
                });
                setInputData({
                    dailyPrice: "",
                    nbrJours: "",
                    savingAmount: 0,
                    fraisKilom: 0,
                    email: "",
                    idEmployee: 0,
                    employeeFirstName: "",
                    employeeLastName: "",
                    employeeJob: "",
                    ndf: 0,
                    pfg : value
                });
                setPieValues({
                    v1: 0,
                    v2: 0,
                    v3: 0
                });
            } else {
                setData({
                    dailyPrice: props.SimIdToUpdate.dailyPrice || "0",
                    nbreOfDays: props.SimIdToUpdate.nbreOfDays || "0",
                    savingAmount: props.SimIdToUpdate.savingAmount || "0",
                    chiffreAffaire: props.SimIdToUpdate.chiffreAffaire || "0",
                    fraisDeGestion: props.SimIdToUpdate.fraisDeGestion || "0",
                    panierRepas: props.SimIdToUpdate.panierRepas || "0",
                    fraisAnnexe: props.SimIdToUpdate.fraisAnnexe || "0",
                    ik: props.SimIdToUpdate.ik || "0",
                    ndf: props.SimIdToUpdate.ndf || "0",
                    fraisprofessionnels: props.SimIdToUpdate.fraisprofessionnels || "0",
                    primeDapportDaffaire: props.SimIdToUpdate.primeDapportDaffaire || "0",
                    indemniteCongePaye: props.SimIdToUpdate.indemniteCongePaye || "0",
                    salaireBrute: props.SimIdToUpdate.salaireBrute || "0",
                    salaireNetImposable: props.SimIdToUpdate.salaireNetImposable || "0",
                    salaireOptimiseNet: props.SimIdToUpdate.salaireOptimiseNet || "0",
                    reserve: props.SimIdToUpdate.reserve || "0",
                    reste: props.SimIdToUpdate.reste || "0",
                    compteCourant: props.SimIdToUpdate.compteCourant || "0",
                    salaireBrutDebase: props.SimIdToUpdate.salaireBrutDebase || "0"
                });

                setInputData({
                    dailyPrice: props.SimIdToUpdate.dailyPrice || "",
                    nbrJours: props.SimIdToUpdate.nbreOfDays || "",
                    savingAmount: props.SimIdToUpdate.savingAmount || 0,
                    fraisKilom: props.SimIdToUpdate.fraisKilom || 0,
                    email: props.SimIdToUpdate.email || "",
                    idEmployee: props.SimIdToUpdate.id || 0,
                    employeeFirstName: props.SimIdToUpdate.firstName || "",
                    employeeLastName: props.SimIdToUpdate.lastName || "",
                    employeeJob: props.SimIdToUpdate.fonction || "",
                    ndf: props.SimIdToUpdate.ndf || 0,
                    pfg: props.SimIdToUpdate.pfg ||0
                });
                setPieValues({
                    v1: props.SimIdToUpdate.salaireOptimiseNet,
                    v2: props.SimIdToUpdate.fraisDeGestion,
                    v3: props.SimIdToUpdate.fraisAnnexe
                });


            }
        }
    }, [props.modalOpen, props.SimIdToUpdate]);

    
    return (
        <Modal isOpen={props.modalOpen} toggle={props.toggleModal} className="add-porspection">
            <ModalHeader toggle={props.toggleModal}>Create Prospection</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="panel left-panel" md={6}>
                        <Form className="Form-prospection">
                            <FormGroup className="row">
                                <Label for="consultant" className="col-sm-6">Consultant</Label>
                                <Input
                                    type="select"
                                    name="consultant"
                                    onChange={(e) => handleSelectConsultant(e.target.value)}
                                    id="consultant"
                                    className="col-sm-6">
                                    <option>Select...</option>
                                    {employees.map(emp => (
                                        <option value={emp.id}>{emp.firstName} | {emp.lastName}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <CustomInput
                                type="text"
                                name="employeeFirstName"
                                value={inputData.employeeFirstName}
                                onChange={(e) => updateInputData('employeeFirstName', e.target.value)}
                                label="Nom  *"
                            />
                            <CustomInput
                                type="text"
                                name="employeeLastName"
                                value={inputData.employeeLastName}
                                onChange={(e) => updateInputData('employeeLastName', e.target.value)}
                                label="Prénom  *"
                            />
                            <CustomInput
                                type="text"
                                name="employeeJob"
                                value={inputData.employeeJob}
                                onChange={(e) => updateInputData('employeeJob', e.target.value)}
                                label="Fonction"
                            />
                            <CustomInput
                                type="email"
                                name="email"
                                value={inputData.email}
                                onChange={(e) => updateInputData('email', e.target.value)}
                                label="Email"
                            />
                            <CustomInput
                                type="number"
                                name="pfg"
                                value={inputData.pfg}
                                onChange={(e) => updateInputData('pfg', e.target.value)}
                                label="Frais de gestion  *"
                            />
                            <CustomInput
                                type="number"
                                name="dailyPrice"
                                value={inputData.dailyPrice}
                                onChange={(e) => updateInputData('dailyPrice', e.target.value)}
                                label="TJM  *"
                            />
                            <CustomInput
                                type="number"
                                name="nbrJours"
                                value={inputData.nbrJours}
                                onChange={(e) => updateInputData('nbrJours', e.target.value)}
                                label="Nombre de jours *"
                                min={0}
                                max={30}
                            />
                            <CustomAccordion
                                nbreOfDays={inputData.nbrJours}
                                ndf={inputData.ndf}
                                setNdf={(value) => updateInputData('ndf', value)}
                                FraisProIk={inputData.fraisKilom}
                                setFraisProIk={(value) => updateInputData('fraisKilom', value)}
                            />
                            <FormGroup className="row ">
                                <InputGroup className="NDF-Input mt-2 ml-1">
                                    <Label for="days" className="col-sm-6">Épargne</Label>
                                    <Input
                                        className="ml-6"
                                        name="epargne"
                                        value={inputData.savingAmount}
                                        onChange={(e) => updateInputData('savingAmount', e.target.value)}
                                    />
                                    <InputGroupAddon addonType="append">€</InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            <Button className="mb-3" onClick={handleClickCalculate} color="primary">Calculer</Button>
                            <CustomAccordion2 />
                        </Form>
                    </Col>
                    <Col className="panel right-panel" md={6}>
                        <table className="table-simulation">
                            <tbody>
                                <tr>
                                    <td>Nombre de jours</td>
                                    <td colspan="3">{data.nbreOfDays}</td>

                                </tr>
                                <tr>
                                    <td>TJM</td>
                                    <td colspan="3">{formatNmbr(data.dailyPrice)} €</td>
                                </tr>
                                <tr>
                                    <td>Chiffre d'affaire</td>
                                    <td colspan="3">{formatNmbr(data.chiffreAffaire)} €</td>
                                </tr>
                                <tr>
                                    <td>Frais de gestion</td>
                                    <td colspan="3">{formatNmbr(data.fraisDeGestion)} €</td>
                                </tr>
                                <tr>
                                    <td>Frais annexes (mutuelle, prevoyance, CVAE ...)</td>
                                    <td colspan="3">{formatNmbr(data.fraisAnnexe)} €</td>
                                </tr>
                                <tr>
                                    <td>Compte courant</td>
                                    <td colspan="3" >{formatNmbr(data.compteCourant)} €</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">Frais professionnels</td>
                                    <td rowspan="3">{formatNmbr(data.fraisprofessionnels)} €</td>
                                    <td>Panier repas</td>
                                    <td>{formatNmbr(data.panierRepas)} €</td>
                                </tr>
                                <tr>
                                    <td>IK</td>
                                    <td>{formatNmbr(data.ik)} €</td>
                                </tr>
                                <tr>
                                    <td>NDF</td>
                                    <td>{formatNmbr(data.ndf)} €</td>
                                </tr>

                                <tr>
                                    <td>Epargne</td>
                                    <td colspan="3" >{formatNmbr(data.savingAmount)} €</td>
                                </tr>
                                <tr>
                                    <td>Reste (Compte courant - Frais Pro - Epargne)</td>
                                    <td colspan="3" >{formatNmbr(data.reste)} €</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">Salaire brute total</td>
                                    <td rowspan="3">{formatNmbr(data.salaireBrute)} €</td>
                                    <td>Salaire brute de base</td>
                                    <td>{formatNmbr(data.salaireBrutDebase)} €</td>
                                </tr>
                                <tr>
                                    <td>Prime d'apport d'affaire</td>
                                    <td>{formatNmbr(data.primeDapportDaffaire)} €</td>
                                </tr>
                                <tr>
                                    <td>Indemnité congé payé</td>
                                    <td>{data.indeminiteCongePaye} €</td>
                                </tr>
                                <tr>
                                    <td>Salaire net imposable</td>
                                    <td colspan="3"  >{data.salaireNetImposable} €</td>
                                </tr>
                                <tr className="highlight-green">
                                    <td>Salaire optimisé</td>
                                    <td colspan="3" >{data.salaireOptimiseNet} €</td>
                                </tr>
                                <tr className="highlight-yellow">
                                    <td>Reserve</td>
                                    <td colspan="3" >{data.reserve} €</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="chart-container">
                            <RechartsPieChart data={PieValues} />
                        </div>

                        <MultiOptionButton handleOptionClick={handleOptionClick} toggleModal={props.toggleModal}/>
                    </Col>
                </Row>
            </ModalBody >

        </Modal >
    )
}

const CustomInput = ({
                         type,
                         name,
                         id,
                         value,
                         onChange,
                         placeholder,
                         label
                     }) => {
    return (
        <FormGroup className="row">
            <Label for={id} className="col-sm-6">{label}</Label>
            <Input
                type={type}
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="col-sm-6"
            />
        </FormGroup>
    );
};
function mergeData(primary, secondary, tertiary) {
    // 1. Mettre à jour le premier objet avec les valeurs non nulles ou vides du deuxième objet
    Object.keys(secondary).forEach(key => {
        if (secondary[key] !== null && secondary[key] !== "") {
            primary[key] = secondary[key];
        }
    });

    // 2. Mettre à jour le premier objet avec les valeurs du troisième objet
    Object.keys(tertiary).forEach(key => {
        // On vérifie que la valeur actuelle n'a pas été modifiée par le deuxième objet
        if (primary[key] === null || primary[key] === "" || !primary.hasOwnProperty(key)) {
            primary[key] = tertiary[key];
        }
    });

    return primary;
}
export default ModalAddProspection