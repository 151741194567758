import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Form, FormGroup, Label, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import './CraPage.scss';
import { getCurrentInfo, getMissionById, getCraRecords, saveCra } from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import { getDayOfWeek, getWeekNumber, isWeekend, getDaysInMonth } from "./functions";

const Cra = () => {
    const [missionsList, setMissionsList] = useState([]);
    const [mission, setMission] = useState("");
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [isCraExisting, setIsCraExisting] = useState(false);
    const [craId, setCraId] = useState(null);
    const [craStatus, setCraStatus] = useState(null);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [message, setMessage] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getCurrentInfo();
                if (res.data?.employeeId) {
                    const missionRes = await getMissionById(res.data.employeeId);
                    setMissionsList(missionRes.data);
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (mission) fetchCraData(mission, month, year);
    }, [mission, month, year]);

    useEffect(() => {
        const daysArray = getDaysInMonth(month, year).map(date => ({
            date,
            serviceDay: 0,
            offDay: 0,
            onSiteTelework: 'O'
        }));
        setDaysInMonth(daysArray);
        setCraId(null);
        setIsCraExisting(false);
        setCraStatus(null);
        setMessage("");
    }, [mission, month, year]);

    const fetchCraData = async (missionId, month, year) => {
        try {
            const res = await getCraRecords(missionId, month, year);
            if (res.data?.days?.length > 0) {
                const fetchedDays = getDaysInMonth(month, year).map((date, index) => ({
                    date,
                    serviceDay: res.data.days[index]?.serviceDay || 0,
                    offDay: res.data.days[index]?.offDay || 0,
                    onSiteTelework: res.data.days[index]?.onSiteTelework || 'O',
                }));
                setDaysInMonth(fetchedDays);
                setIsCraExisting(true);
                setCraId(res.data.id);
                setCraStatus(res.data.status);
                // Check craStatus and set message based on API response
                if (res.data.status === 1) {
                    setMessage(res.data.message || "CRA non valide , à refaire");
                } else if (res.data.status === 2) {
                    setMessage("Le Compte Rendu d'Activité de ce mois est déjà Validé");
                }
            } else {
                resetDaysArray();
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du CRA", error);
        }
    };

    const resetDaysArray = () => {
        const daysArray = getDaysInMonth(month, year).map(date => ({
            date,
            serviceDay: 0,
            offDay: 0,
            onSiteTelework: 'O'
        }));
        setDaysInMonth(daysArray);
        setIsCraExisting(false);
        setCraId(null);
        setCraStatus(null);
        setMessage("")
    };

    const handleServiceDayChange = (index, value) => {
        const validValues = ['0', '0.5', '1'];
        if (value === '' || value === null || value === undefined) {
            value = '0';
        }
        value = value.replace(/^0+/, '');
        if (value === '') value = '0';
        const numericValue = parseFloat(value);
        if (validValues.includes(value) || numericValue === 0 || numericValue === 0.5 || numericValue === 1) {
            const updatedDays = [...daysInMonth];
            updatedDays[index].serviceDay = numericValue;
            setDaysInMonth(updatedDays);
        }
    };

    const calculateTotalServiceDays = () => {
        return daysInMonth.reduce((total, day) => total + day.serviceDay, 0);
    };

    const handleSubmit = async (status = 1) => {
        const craData = {
            id: craId,
            missionId: mission,
            status: status,
            month,
            year,
            days: daysInMonth.map(day => ({
                serviceDay: day.serviceDay,
                offDay: day.offDay,
                onSiteTelework: day.onSiteTelework,
                date: day.date.toISOString().split('T')[0]
            })),
            totalServiceDays: calculateTotalServiceDays()
        };
        try {
            await saveCra(craData);
            const message = status === 2
                ? 'Le CRA a été validé avec succès. Vous ne pourrez plus le modifier.'
                : 'Le CRA a été enregistré avec succès.';
            showToast(message);
            if(status === 2){
                fetchCraData(mission, month, year);
            }
        } catch (error) {
            showToast("Le CRA n'a pas pu être enregistré.", "error");
        }
    };

    const toggleValidationModal = () => {
        setShowValidationModal(!showValidationModal);
    };

    const areInputsDisabled = !mission || (craStatus !== 1 && craStatus !== null);

    return (
        <Widget>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label>Mission</Label>
                            <Input
                                type="select"
                                value={mission}
                                onChange={(e) => setMission(e.target.value)}
                            >
                                <option value="">Sélectionner une mission</option>
                                {missionsList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Mois</Label>
                            <Input
                                type="select"
                                value={month}
                                onChange={(e) => setMonth(parseInt(e.target.value))}
                                disabled={!mission}
                            >
                                {Array.from({ length: 12 }, (_, i) => i + 1).map(m => (
                                    <option key={m} value={m}>{m}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Année</Label>
                            <Input
                                type="select"
                                value={year}
                                onChange={(e) => setYear(parseInt(e.target.value))}
                                disabled={!mission}
                            >
                                {Array.from({ length: 10 }, (_, i) => 2024 + i).map(y => (
                                    <option key={y} value={y}>{y}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered className="cra-table">
                            <thead>
                            <tr>
                                <th>Semaine</th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index}>{getWeekNumber(day.date)}</th>
                                ))}
                                <th>Total</th>
                            </tr>
                            <tr>
                                <th>Jours</th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>
                                        {day.date ? day.date.getDate() : 'N'}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>
                                        {getDayOfWeek(day.date)}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Jours prestés</td>
                                {daysInMonth.map((day, index) => (
                                    <td key={index} className={isWeekend(day.date) ? 'weekend' : ''}>
                                        <input
                                            className="cra_input"
                                            type="text"
                                            value={day.serviceDay}
                                            onChange={(e) => handleServiceDayChange(index, e.target.value)}
                                            disabled={areInputsDisabled || isWeekend(day.date)}
                                        />
                                    </td>
                                ))}
                                <td>{calculateTotalServiceDays()}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="primary" onClick={() => handleSubmit(1)} disabled={!mission || craStatus === 2}>
                            Enregistrer
                        </Button>
                        {' '}
                        <Button color="success" onClick={toggleValidationModal} disabled={!mission || craStatus === 2}>
                            Valider
                        </Button>
                    </Col>
                    <Col>
                        <span className="error-login-message">{message} </span>
                    </Col>
                </Row>
            </Form>

            {/* Modal for validation */}
            <Modal isOpen={showValidationModal} toggle={toggleValidationModal}>
                <ModalBody>
                    Vous êtes sur le point de valider votre CRA. Une fois validé, il ne sera plus possible de le modifier.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggleValidationModal}>Non</Button>
                    <Button color="success" onClick={() => { handleSubmit(2); toggleValidationModal(); }}>Oui</Button>
                </ModalFooter>
            </Modal>
        </Widget>
    );
};

export default Cra;
