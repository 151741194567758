import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';

const AddEmployeeModal = ({ isOpen, toggle, handleAdd }) => {
    const [employeeData, setEmployeeData] = useState({
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        duty: "",
        nationalty: "",
        address: "",
        socialSecurityNumber: "",
    });

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateFields = () => {
        const errors = {};

        // Required field validation
        Object.entries(employeeData).forEach(([key, value]) => {
            if (!value.trim()) {
                errors[key] = "Ce champ est obligatoire.";
            }
        });

        // Specific field validations
        if (employeeData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeData.email)) {
            errors.email = "Adresse email non valide.";
        }

        if (employeeData.phoneNumber && !/^\+?[0-9]{10,15}$/.test(employeeData.phoneNumber)) {
            errors.phoneNumber = "Numéro de téléphone non valide.";
        }

        return errors;
    };

    const handleSubmit = () => {
        const errors = validateFields();

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setValidationErrors({});
        handleAdd(employeeData);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            <ModalHeader toggle={toggle}>Ajouter un Employé</ModalHeader>
            <ModalBody>
                <Row>
                    {[
                        { label: "Prénom", name: "firstName", type: "text" },
                        { label: "Nom", name: "lastName", type: "text" },
                        { label: "Date de Naissance", name: "birthDate", type: "date" },
                        { label: "Email", name: "email", type: "email" },
                        { label: "Téléphone", name: "phoneNumber", type: "text" },
                        { label: "Fonction", name: "duty", type: "text" },
                        { label: "Nationalité", name: "nationalty", type: "text" },
                        { label: "Adresse", name: "address", type: "text" },
                        { label: "Numéro de Sécurité Sociale", name: "socialSecurityNumber", type: "text" },
                    ].map(({ label, name, type }, index) => (
                        <Col md="6" key={name} className="mb-3">
                            <FormGroup>
                                <Label for={name}>{label}:</Label>
                                <Input
                                    type={type}
                                    name={name}
                                    id={name}
                                    value={employeeData[name]}
                                    onChange={handleInputChange}
                                    invalid={!!validationErrors[name]}
                                />
                                {validationErrors[name] && (
                                    <FormText color="danger">{validationErrors[name]}</FormText>
                                )}
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Annuler
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Ajouter
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddEmployeeModal;
