import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import './OrganizationsPage.scss';
import { useHistory } from 'react-router-dom';
import { getOrganizations, getLegalstatusAll, saveOrganization, deleteOrganization } from '../../services/configService';
import showToast from "../../components/NotificationToast/toastUtils";
import { startLoading, stopLoading } from "../../actions/loading";
import { useDispatch} from "react-redux";
import InputCustom from '../../components/InputCustom/InputCustom';
import OrganizationsHeader from "./organizationsHeader";

const Organizations = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    siretNumber: '',
    tvaNumber: '',
    email: '',
    mobileNumber: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    zipcode: '',
    city: '',
    country: '',
    website: '',
    registrationDate: '',
    legalStatus: '',
    birthDate: '', // Ajout de la date de naissance
    file: null, // Pour le fichier
  });

  const [errors, setErrors] = useState({});
  const [legalStatusOptions, setLegalStatusOptions] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchEnvironments();
  }, []);


  const fetchEnvironments = async () => {
    dispatch(startLoading());
    try {
      const initialEnvironments = await getOrganizations();
      setEnvironments(initialEnvironments.data);
      const res = await getLegalstatusAll();
      setLegalStatusOptions(res.data);
    } catch (error) {
      console.error('Error fetching environments:', error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleAdd = async () => {
    if (validateForm()) {
      try {
        const formDataToSend = new FormData();

        Object.keys(formData).forEach(key => {
          if (formData[key]) { // Vérifiez que les champs ne sont pas vides
            formDataToSend.append(key, formData[key]);
          }
        });

        await saveOrganization(formDataToSend);
        showToast('L\'organisation a été enregistrée avec succès.');
        toggleAddModal();
        fetchEnvironments();
      } catch (error) {
        showToast("L'organisation n'a pas pu être enregistrée. " + error.message, "error");
      }
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      validationErrors.name = 'Nom est obligatoire';
      isValid = false;
    }

    if (!formData.siretNumber.trim()) {
      validationErrors.siretNumber = 'Numéro Siret est obligatoire';
      isValid = false;
    }

    if (!formData.legalStatus.trim()) {
      validationErrors.legalStatus = 'Statut légal est obligatoire';
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const handleDelete = async () => {
    try {
      await deleteOrganization(selectedEnvironmentId);
      showToast('L\'Environnement sélectionné a été supprimé avec succès.');
      fetchEnvironments();
    } catch (error) {
      showToast('Une erreur s\'est produite lors de la suppression d\'Environnement.', "error");
    }
    setSelectedEnvironmentId(null);
    toggleDeleteModal();
  };

  const formatDateForInput = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  return (
      <div className="Select-environement select-environment-background">
        <OrganizationsHeader/>
        <div className="d-flex align-items-center justify-content-center vh-100">
          <Container>
            <Row className="justify-content-center">
              <h1 className="text-center mb-4">Environnement de Travail</h1>
            </Row>
            <Row className="justify-content-center ">
              {environments.map((environment, index) => (
                  <Col key={index} xs="4" className="text-center mb-3">
                    <div className="environment-square" onClick={() => history.push("/home/prospections")}>
                      <i className="eva eva-home-outline company-logo" />
                      <p className="body-1 mb-0">{environment.name}</p>
                      <p className="body-3 text-muted mb-0">{environment.adressLine1}</p>
                      <p className="body-3 text-muted mb-0">{environment.city}</p>
                      <p className="body-3 text-muted mb-0">{environment.country}</p>
                      <i className="eva eva-trash-2-outline delete-icon" onClick={(e) => { e.stopPropagation(); setSelectedEnvironmentId(environment.id); toggleDeleteModal(); }} />
                      <i className="eva eva-edit-outline edit-icon" />
                    </div>
                  </Col>
              ))}
              <Col xs="4" className="text-center mb-3">
                <Button className="add-environment-btn" onClick={toggleAddModal}>+</Button>
              </Col>
            </Row>
          </Container>

          <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
            <ModalHeader toggle={toggleAddModal}>Ajouter un environnement</ModalHeader>
            <ModalBody>
              <InputCustom
                  label="Nom Environnement"
                  type="text"
                  id="name"
                  placeholder="Nom"
                  value={formData.name}
                  onChange={handleChange}
                  error={errors.name}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Numéro Siret"
                  type="text"
                  id="siretNumber"
                  placeholder="Numéro Siret"
                  value={formData.siretNumber}
                  onChange={handleChange}
                  error={errors.siretNumber}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Numéro TVA"
                  type="text"
                  id="tvaNumber"
                  placeholder="Numéro TVA"
                  value={formData.tvaNumber}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Adresse mail"
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Date d'enregistrement"
                  type="date"
                  id="registrationDate"
                  placeholder="Date d'enregistrement"
                  value={formatDateForInput(formData.registrationDate)}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Date de naissance"
                  type="date"
                  id="birthDate"
                  placeholder="Date de naissance"
                  value={formatDateForInput(formData.birthDate)}
                  onChange={handleChange}
                  error={errors.birthDate}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Adresse ligne 1"
                  type="text"
                  id="addressLine1"
                  placeholder="Adresse ligne 1"
                  value={formData.addressLine1}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Adresse ligne 2"
                  type="text"
                  id="addressLine2"
                  placeholder="Adresse ligne 2"
                  value={formData.addressLine2}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Code postal"
                  type="text"
                  id="zipcode"
                  placeholder="Code postal"
                  value={formData.zipcode}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Ville"
                  type="text"
                  id="city"
                  placeholder="Ville"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Pays"
                  type="text"
                  id="country"
                  placeholder="Pays"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Site web"
                  type="text"
                  id="website"
                  placeholder="Site web"
                  value={formData.website}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Numéro mobile"
                  type="text"
                  id="mobileNumber"
                  placeholder="Numéro mobile"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <InputCustom
                  label="Numéro de téléphone"
                  type="text"
                  id="phoneNumber"
                  placeholder="Numéro de téléphone"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-100 mb-3"
              />
              <FormGroup>
                <Label for="fileUpload" className="label-input">logo :</Label>
                <Input
                    type="file"
                    id="fileUpload"
                    onChange={handleFileChange}
                    className="w-100 mb-3"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleAdd}>Ajouter</Button>
              <Button color="secondary" onClick={toggleAddModal}>Annuler</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
            <ModalHeader toggle={toggleDeleteModal}>Supprimer un environnement.</ModalHeader>
            <ModalBody>
              Êtes-vous sûr de vouloir supprimer cet environnement ?
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={handleDelete}>Supprimer</Button>
              <Button color="secondary" onClick={toggleDeleteModal}>Annuler</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
  );
};

export default Organizations;
