import React, { useEffect, useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, Label, FormGroup, InputGroup, InputGroupAddon, Input, Row, Col } from 'reactstrap';
import './CustomAccordion.scss';
import voitureicon from "../../assets/52120.png";
import ttvicon from "../../assets/3122193.png";
import { calculateFraisKilo, calculateFraisTtv } from '../../services/configService';
import { formatNmbr } from '../../services/OutilService';

const CustomAccordion = ({ nbreOfDays, ndf, setNdf, FraisProIk, setFraisProIk }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSquare, setSelectedSquare] = useState(0);
    const [fraisTtvConf, setFraisTtvConf] = useState(0);
    const [FraisKilo, setFraisKilo] = useState(0);
    const [FraisKiloConf, setFraisKiloConf] = useState(0);
    const [FraisProTot, setFraisProTot] = useState(0);

    useEffect(() => {
        const fraisProIkValue = selectedSquare === 0 ? FraisKiloConf : fraisTtvConf;
        setFraisProIk(fraisProIkValue);
    }, [selectedSquare, FraisKiloConf, fraisTtvConf]);

    useEffect(() => {
        const fraisProTotValue = parseFloat(FraisProIk) + parseFloat(ndf);
        setFraisProTot(fraisProTotValue);
    }, [FraisProIk, ndf]);

    const toggleAccordion = () => setIsOpen(!isOpen);

    return (
        <Card className="custom-card mb-2">
            <CardHeader className="custom-card-header" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                <div className="row">
                    <Label className="col-sm-9">Frais professionnels</Label>
                    <Label className="col-sm-3">
                        {formatNmbr(FraisProTot)} €
                        <span className={isOpen ? 'caret-4-desc' : 'caret-4-asc'}></span>
                    </Label>
                </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormGroup className="row ml-2">
                        <InputGroup className="NDF-Input mt-2 ml-1">
                            <Label for="days" className="col-sm-6">NDF</Label>
                            <Input type="number" value={ndf} onChange={(e) => setNdf(e.target.value)} />
                            <InputGroupAddon addonType="append">€ / mois</InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col>
                            <Square
                                selected={selectedSquare === 0}
                                onClick={() => setSelectedSquare(0)}
                                header="Frais Kilométriques"
                                price={FraisKiloConf}
                                icon={voitureicon}
                                nbreOfDays={nbreOfDays}
                                FraisKilo={FraisKilo}
                                setFraisKilo={setFraisKilo}
                                setFraisKiloConf={setFraisKiloConf}
                                setFraisTtvConf={setFraisTtvConf}
                                type={1}
                            />
                        </Col>
                        <Col>
                            <Square
                                selected={selectedSquare === 1}
                                onClick={() => setSelectedSquare(1)}
                                header="Frais Télétravail"
                                price={fraisTtvConf}
                                icon={ttvicon}
                                nbreOfDays={nbreOfDays}
                                FraisKilo={FraisKilo}
                                setFraisKilo={setFraisKilo}
                                setFraisKiloConf={setFraisKiloConf}
                                setFraisTtvConf={setFraisTtvConf}
                                type={2}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}

const Square = ({ selected, onClick, header, price, icon, nbreOfDays, FraisKilo, setFraisKilo, setFraisKiloConf, setFraisTtvConf, type }) => {
    const [showModal, setShowModal] = useState(false);
    const [distance, setDistance] = useState(0);
    const [chevaux, setChevaux] = useState(0);
    const [FratTTVinputs, setInputs] = useState({
        superficieTotal: '',
        loyer: '',
        assuranceHabitation: '',
        electrecite: '',
        taxeOm: '',
        superficieTTV: '',
        teleMobile: '',
        boxInter: '',
        taxeHabitation: '',
        navigo: ''
    });
    const [fraisTTV, setFraisTTV] = useState(0);

    const handleSelectChange = (event) => setChevaux(event.target.value);

    const handleInputChangeFTTV = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDetailClick = (e) => {
        e.preventDefault();
        setShowModal(!showModal);
    };

    const handleCalculateFraisTtv = async (e) => {
        e.preventDefault();
        const result = await calculateFraisTtv(FratTTVinputs);
        setFraisTTV(result.data);
    };

    const handleCalculateAndRefresh = (e) => {
        e.preventDefault();
        calculateFraisTtv(FratTTVinputs).then((result) => {
            setFraisTTV(result.data);
            setFraisTtvConf(result.data);  // Actualise après avoir calculé
            setShowModal(false);
        });
    };

    useEffect(() => {
        if (chevaux && distance && nbreOfDays) {
            calculateFraisKilo(distance, chevaux, nbreOfDays).then((res) => setFraisKilo(res.data));
        }
    }, [distance, chevaux, nbreOfDays]);

    const handleRefreshFraisKilo = (e) => {
        e.preventDefault();
        setFraisKiloConf(FraisKilo);
        setShowModal(false);
    };

    const handleRefreshFraisTTV = (e) => {
        e.preventDefault();
        setFraisTtvConf(fraisTTV);
        setShowModal(false);
    };

    return (
        <div
            style={{
                position: 'relative',
                width: '230px',
                height: '100px',
                backgroundColor: selected ? '#00fa9a' : '#f0f0f0',
                margin: '10px',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <input
                type="checkbox"
                style={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    zIndex: 1,
                    transform: 'scale(1.5)',
                    width: '15px',
                    height: '15px',
                    border: '2px solid black',
                    borderRadius: '5px',
                }}
                onChange={() => { }}
                checked={selected}
            />
            <div className="simulation-container">
                <img
                    src={icon}
                    alt="icon"
                    className="simulation-image"
                />
                <div className="simulation-details">
                    <div className="simulation-header">{header}</div>
                    <div className="simulation-price">{formatNmbr(price)}</div>
                </div>
            </div>
            <a
                href="#"
                className="ancre-details"
                onClick={handleDetailClick}
            >
                Details
            </a>
            {showModal && (
                <div className="small-modal">
                    {type === 1 ? (
                        <div className="modal-content">
                            <span className="close" onClick={handleDetailClick}>&times;</span>
                            <InputGroup className="NDF-Input mt-2 ml-1">
                                <Label className="col-sm-6">Nbre de CH. Fiscaux</Label>
                                <Input type="select" value={chevaux} onChange={handleSelectChange}>
                                    <option value="0">Selectionnez les chevaux</option>
                                    <option value="4">4 CV</option>
                                    <option value="5">5 CV</option>
                                    <option value="6">6 CV</option>
                                    <option value="7">7 CV et plus</option>
                                </Input>
                            </InputGroup>
                            <InputGroup className="NDF-Input mt-2 ml-1">
                                <Label className="col-sm-6">Distance Parcourue</Label>
                                <Input type="number" placeholder="distance" value={distance} onChange={(e) => setDistance(e.target.value)} />
                                <InputGroupAddon addonType="append">Km/Jrs</InputGroupAddon>
                            </InputGroup>
                            <div className="text-simulation-calcul">
                                <span>{formatNmbr(FraisKilo)} €</span>
                            </div>
                            <button className="mt-2" onClick={handleRefreshFraisKilo}>Valider</button>
                        </div>
                    ) : (
                        <div className="modal-content">
                            <span className="close" onClick={handleDetailClick}>&times;</span>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <Label htmlFor="superficieTotal">Superficie Total</Label>
                                    <Input type="number" id="superficieTotal" name="superficieTotal" onChange={handleInputChangeFTTV} value={FratTTVinputs.superficieTotal} />
                                </div>
                                <div className="col-sm-6">
                                    <Label htmlFor="superficieTTV">Superficie TTV</Label>
                                    <Input type="number" id="superficieTTV" name="superficieTTV" onChange={handleInputChangeFTTV} value={FratTTVinputs.superficieTTV} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <Label htmlFor="loyer">Loyer Mensuel</Label>
                                    <Input type="number" id="loyer" name="loyer" onChange={handleInputChangeFTTV} value={FratTTVinputs.loyer} />
                                </div>
                                <div className="col-sm-6">
                                    <Label htmlFor="assuranceHabitation">Assurance Habitation</Label>
                                    <Input type="number" id="assuranceHabitation" name="assuranceHabitation" onChange={handleInputChangeFTTV} value={FratTTVinputs.assuranceHabitation} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <Label htmlFor="electrecite">Electricité Mensuelle</Label>
                                    <Input type="number" id="electrecite" name="electrecite" onChange={handleInputChangeFTTV} value={FratTTVinputs.electrecite} />
                                </div>
                                <div className="col-sm-6">
                                    <Label htmlFor="taxeOm">Taxe Ordures Ménagères</Label>
                                    <Input type="number" id="taxeOm" name="taxeOm" onChange={handleInputChangeFTTV} value={FratTTVinputs.taxeOm} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <Label htmlFor="teleMobile">Téléphone Mobile</Label>
                                    <Input type="number" id="teleMobile" name="teleMobile" onChange={handleInputChangeFTTV} value={FratTTVinputs.teleMobile} />
                                </div>
                                <div className="col-sm-6">
                                    <Label htmlFor="boxInter">Box Internet</Label>
                                    <Input type="number" id="boxInter" name="boxInter" onChange={handleInputChangeFTTV} value={FratTTVinputs.boxInter} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <Label htmlFor="taxeHabitation">Taxe Habitation</Label>
                                    <Input type="number" id="taxeHabitation" name="taxeHabitation" onChange={handleInputChangeFTTV} value={FratTTVinputs.taxeHabitation} />
                                </div>
                                <div className="col-sm-6">
                                    <Label htmlFor="navigo">Pass Navigo</Label>
                                    <Input type="number" id="navigo" name="navigo" onChange={handleInputChangeFTTV} value={FratTTVinputs.navigo} />
                                </div>
                            </div>
                            <button className="mt-2" onClick={handleCalculateAndRefresh}>Valider</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CustomAccordion;
