import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, FormGroup, FormText } from "reactstrap";
import { Badge } from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";

import profileImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import {getCurrentInfo} from "../../services/configService"; // Assuming this is the function for fetching user info

const Profile = (props) => {
    const [userInfo, setUserInfo] = useState({
        id: 0,
        name: "",
        surname: "",
        username: "",
        employeeId: 0,
        authorities: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCurrentInfo();
                setUserInfo(data.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="profile-page">
            <Container className="col-12">
                <Row className="d-flex align-items-center">
                    <Col xs={12} lg={6} className="left-column">
                        <Widget className="widget-profile widget-p-lg">
                            <div className="d-flex align-items-center justify-content-between py-3">
                                <p className="profile-header mb-0">Profil Utilisateur</p>
                                <div className="logo-block">
                                    <SofiaLogo />
                                    <p className="mb-0">N2E-IT CRM</p>
                                </div>
                            </div>
                            <div className="profile-info my-2">
                                <p>Bienvenue, <b>{userInfo.name} {userInfo.surname}</b>!</p>
                            </div>
                            <div className="user-details">
                                <FormGroup className="my-3">
                                    <FormText>Mail</FormText>
                                    <p className="input-transparent pl-3">{userInfo.username}</p>
                                </FormGroup>
                                <FormGroup className="my-3">
                                    <FormText>Employee ID</FormText>
                                    <p className="input-transparent pl-3">{userInfo.employeeId}</p>
                                </FormGroup>
                                <FormGroup className="my-3">
                                    <FormText>Autorités</FormText>
                                    <div className="input-transparent pl-3">
                                        {userInfo.authorities.map((auth, index) => (
                                            <Badge key={index} color="secondary" className="mr-2">
                                                {auth.name}
                                            </Badge>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="bg-widget d-flex justify-content-center">
                                <Button className="rounded-pill my-3" color="primary">
                                    Réinitialiser le mot de passe
                                </Button>
                            </div>
                        </Widget>
                    </Col>
                    <Col xs={0} lg={6} className="right-column">
                        <div>
                            <img src={profileImage} alt="Profile" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default withRouter(Profile);
