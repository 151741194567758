import React, { useState} from 'react';
import s from "../../components/Header/Header.module.scss";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem, NavLink} from "reactstrap";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import { useHistory } from 'react-router-dom';

const OrganizationsHeader = (props) => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const history = useHistory();
    const toggleNotifications = () => {
        setNotificationsOpen(!notificationsOpen);
    }
    const doLogout = (history) => {
        localStorage.removeItem('token');

        history.push('/login');
    }

    return (
        <Navbar className={`${s.root} d-print-none`}>
            <Nav className="ml-auto">
                <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
                    <DropdownToggle nav caret className="navbar-dropdown-toggle">
                        <span className="small d-none d-sm-block ml-1 mr-2 body-1">Manager User</span>
                    </DropdownToggle>
                    <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
                        <DropdownItem className={s.dropdownProfileItem}><ProfileIcon /><span>Profile</span></DropdownItem>
                        <DropdownItem className={s.dropdownProfileItem}><TasksIcon /><span>Tasks</span></DropdownItem>
                        <DropdownItem className={s.dropdownProfileItem}><MessagesIcon /><span>Messages</span></DropdownItem>
                        <NavItem>
                            <NavLink onClick={() => doLogout(history)} href="#">
                                <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
                            </NavLink>
                        </NavItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>
        </Navbar>
    )
}

export default OrganizationsHeader;