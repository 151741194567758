
export function decodeJWT(token) {
    // Récupérer la partie payload du token JWT
    const base64Url = token.split('.')[1];
    // Remplacer les caractères spécifiques de Base64URL par ceux de Base64 standard
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // Décoder la chaîne Base64
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    // Convertir le payload en objet JavaScript
    const decoded = JSON.parse(jsonPayload);

    // Extraire les rôles
    const roles = decoded.auth;
    let userRole = '';

    // Vérifier les rôles et retourner celui qui correspond
    if (roles.includes('ADMINS_APPLICATIFS')) {
        userRole = 'admin';
    } else if (roles.includes('MANAGER_APPLICATIFS')) {
        userRole = 'manager';
    } else if (roles.includes('CONSULTANTS_APPLICATIFS')) {
        userRole = 'consultant';
    } else {
        userRole = 'unauthorized';
    }

    // Retourner l'objet avec les données du token et le rôle
    return {
        ...decoded,
        role: userRole // Ajouter le rôle dans l'objet retourné
    };
}