import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';

const EditEmployeeModal = ({ isOpen, toggle, selectedEmployee, handleEdit }) => {
    const [employeeData, setEmployeeData] = useState({
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        duty: "",
        nationalty: "",
        address: "",
        socialSecurityNumber: ""
    });

    useEffect(() => {
        if (selectedEmployee) {
            setEmployeeData({
                firstName: selectedEmployee.firstName || "",
                lastName: selectedEmployee.lastName || "",
                birthDate: selectedEmployee.birthDate || "",
                email: selectedEmployee.email || "",
                phoneNumber: selectedEmployee.phoneNumber || "",
                duty: selectedEmployee.duty || "",
                nationalty: selectedEmployee.nationalty || "",
                address: selectedEmployee.address || "",
                socialSecurityNumber: selectedEmployee.socialSecurityNumber || ""
            });
        }
    }, [selectedEmployee]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData({
            ...employeeData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        handleEdit(selectedEmployee.id, employeeData);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Modifier un Employé</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="firstName">Prénom:</Label>
                    <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Prénom"
                        value={employeeData.firstName}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">Nom:</Label>
                    <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Nom"
                        value={employeeData.lastName}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="birthDate">Date de Naissance:</Label>
                    <Input
                        type="date"
                        id="birthDate"
                        name="birthDate"
                        value={employeeData.birthDate}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={employeeData.email}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="phoneNumber">Téléphone:</Label>
                    <Input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Téléphone"
                        value={employeeData.phoneNumber}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="duty">Fonction:</Label>
                    <Input
                        type="text"
                        id="duty"
                        name="duty"
                        placeholder="Fonction"
                        value={employeeData.duty}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="nationality">Nationalité:</Label>
                    <Input
                        type="text"
                        id="nationalty"
                        name="nationalty"
                        placeholder="Nationalité"
                        value={employeeData.nationalty}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="address">Adresse:</Label>
                    <Input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Adresse"
                        value={employeeData.address}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="socialSecurityNumber">Numéro de Sécurité Sociale:</Label>
                    <Input
                        type="text"
                        id="socialSecurityNumber"
                        name="socialSecurityNumber"
                        placeholder="Numéro de Sécurité Sociale"
                        value={employeeData.socialSecurityNumber}
                        onChange={handleChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Annuler
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Modifier
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditEmployeeModal;
