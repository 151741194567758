import React, { useState, useEffect } from 'react';
import { Input, Table, Button } from 'reactstrap';
import { FaFilePdf, FaUpload, FaCheck } from 'react-icons/fa';
import 'eva-icons';
import './ContractDetails.scss';
import { loadPdf, uploadPdfClient } from "../../services/pdfUtils";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import showToast from "../../components/NotificationToast/toastUtils";
import { getContractById } from "../../services/configService";

const ContractDetails = ({ contract }) => {
    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState({ client: null, consultant: null });
    const [uploading, setUploading] = useState({ client: false, consultant: false });
    const [contractData, setContractData] = useState(contract);
    const [downloading, setDownloading] = useState({ client: false, consultant: false });

    // Recharger les données de contrat à partir de l'API
    const reloadContractData = async () => {
        try {
            console.log(contractData)
            const updatedContract = await getContractById(contract.id);
            setContractData(updatedContract.data);
            console.log(contractData)
            setUploadedFiles({ client: null, consultant: null });
            setUploading({ client: false, consultant: false });
        } catch (error) {
            showToast('Erreur lors de la mise à jour des données du contrat.', 'error');
        }
    };

    const handleContractDownload = async (isClient) => {
        const type = isClient ? 'client' : 'consultant';
        setDownloading((prev) => ({ ...prev, [type]: true }));

        try {
            await loadPdf(
                contractData.id,
                type,
                isClient ? contractData.name_contrat_cli : contractData.name_contrat_cons || 'contrat_Automatique'
            );
        } finally {
            setDownloading((prev) => ({ ...prev, [type]: false }));
        }
    };

    const handleFileUpload = (event, type) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setUploadedFiles((prev) => ({ ...prev, [type]: file }));
        } else {
            alert('Veuillez télécharger un fichier PDF valide.');
            setUploadedFiles((prev) => ({ ...prev, [type]: null }));
        }
    };

    const handleUpload = async (type) => {
        const file = uploadedFiles[type];
        if (!file) {
            alert(`Veuillez sélectionner un fichier à télécharger pour le ${type === 'client' ? 'client' : 'consultant'}.`);
            return;
        }

        setUploading((prev) => ({ ...prev, [type]: true }));
        dispatch(startLoading());

        try {
            await uploadPdfClient(file, type, contractData.id);
            showToast('Le fichier a été enregistré avec succès.');
            await reloadContractData();  // Recharger les données
        } catch (error) {
            showToast("Le fichier n'a pas pu être enregistré.", 'error');
        } finally {
            setUploading((prev) => ({ ...prev, [type]: false }));
            dispatch(stopLoading());
        }
    };

    const truncateTitle = (title) => title.length > 20 ? `${title.substring(0, 20)}...` : title;
    const truncateFileName = (name) => name.length > 20 ? `${name.substring(0, 20)}...` : name;

    const renderTableRows = (data) => (
        Object.entries(data).map(([label, value]) => (
            <tr key={label}>
                <td><strong>{label}:</strong></td>
                <td>{value || 'N/A'}</td>
            </tr>
        ))
    );
        console.log(contractData)
    return (
        <div className="contract-details">
            {/* Détails du consultant */}
            <div className="Widget_widget__1JBrv">
                <h5 className="table-title"><i data-eva="person" /> Consultant</h5>
                <Table bordered>
                    <tbody>
                    {renderTableRows({
                        'Nom': `${contractData.employee.firstName} ${contractData.employee.lastName}`,
                        'Email': contractData.employee.email,
                        'Téléphone': contractData.employee.phoneNumber || 'Non renseigné',
                        'Date de naissance': new Date(contractData.employee.birthDate).toLocaleDateString(),
                        'Numéro de sécurité sociale': contractData.employee.socialSecurityNumber,
                        'Fonction': contractData.employee.duty,
                        'Nationalité': contractData.employee.nationality || 'Non renseignée'
                    })}
                    </tbody>
                </Table>
            </div>
            {/* Client Details */}
            <div className="Widget_widget__1JBrv" style={{ marginTop: '20px' }}>
                <h5 className="table-title"><i data-eva="briefcase-outline" /> Client</h5>
                <Table bordered>
                    <tbody>
                    {renderTableRows({
                        'Nom de l\'entreprise': contractData.compagny.name,
                        'Numéro SIRET': contractData.compagny.siretNumber,
                        'Numéro TVA': contractData.compagny.tvaNumber,
                        'Date d\'enregistrement': new Date(contractData.compagny.registrationDate).toLocaleDateString(),
                        'Capital social': contractData.compagny.socialCapital,
                        'Site Web': contractData.compagny.website
                    })}
                    </tbody>
                </Table>
            </div>

            {/* Contract Details */}
            <div className="Widget_widget__1JBrv" style={{ marginTop: '20px' }}>
                <h5 className="table-title"><i data-eva="file-text-outline" /> Détails du Contrat</h5>
                <Table bordered>
                    <tbody>
                    {renderTableRows({
                        'Label du Contrat': contractData.label,
                        'Date de début': new Date(contractData.beginDate).toLocaleDateString(),
                        'Date de fin': new Date(contractData.endDate).toLocaleDateString(),
                        'Prix journalier': `${contractData.dailyPrice} €`,
                        'Validité': contractData.valid ? 'Valide' : 'Non valide'
                    })}
                    </tbody>
                </Table>
            </div>

            {/* Télécharger les Contrats */}
            <div className="Widget_widget__1JBrv" style={{textAlign: 'center', marginTop: '20px'}}>
                <h5 className="table-title"><i data-eva="download-outline"/> Télécharger les Contrats</h5>
                <div style={{display: 'flex', justifyContent: 'center', gap: '40px', marginTop: '10px'}}>
                    <div>
                        <a
                            href={contractData.clientContractFile}
                            download
                            onClick={() => handleContractDownload(true)}
                            style={{
                                pointerEvents: !contractData.name_contrat_cli ? 'none' : 'auto',
                                opacity: !contractData.name_contrat_cli ? 0.5 : 1
                            }}
                        >
                            <FaFilePdf style={{ fontSize: '50px', color: !contractData.name_contrat_cli ? '#ccc' : '#e63946' }} />
                            <p>Client: {contractData.name_contrat_cli ? truncateTitle(contractData.name_contrat_cli) : '.'}</p>
                        </a>
                    </div>
                    <div>
                        <a
                            href={contractData.consultantContractFile}
                            download
                            onClick={() => handleContractDownload(false)}
                            style={{ pointerEvents: downloading.consultant ? 'none' : 'auto', opacity: downloading.consultant ? 0.5 : 1 }}
                        >
                            <FaFilePdf style={{ fontSize: '50px', color: '#e63946' }} />
                            <p>Consultant: {contractData.name_contrat_cons ? truncateTitle(contractData.name_contrat_cons) : 'AUTO'}</p>
                        </a>
                    </div>
                </div>
            </div>

            {/* Ajouter les contrats signés */}
            <div className="Widget_widget__1JBrv" style={{ marginTop: '20px' }}>
                <h5 className="table-title"><i data-eva="cloud-upload-outline" /> Ajouter les contrats Signés</h5>
                <div className="upload-section" style={{ display: 'flex', justifyContent: 'center', gap: '40px', marginTop: '10px' }}>
                    {['client', 'consultant'].map((type) => (
                        <div key={type} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <label htmlFor={`${type}-contract-upload`} style={{ cursor: 'pointer' }}>
                                <FaUpload style={{ fontSize: '50px', color: '#e63946' }} />
                                <p> Contrat {type.charAt(0).toUpperCase() + type.slice(1)}</p>
                            </label>
                            <Input
                                id={`${type}-contract-upload`}
                                type="file"
                                onChange={(e) => handleFileUpload(e, type)}
                                style={{ display: 'none' }}
                            />
                            {uploadedFiles[type] && (
                                <p style={{ fontSize: '14px', color: '#333', marginTop: '10px' }}>
                                    <i data-eva="checkmark-outline" /> {truncateFileName(uploadedFiles[type].name)}
                                </p>
                            )}
                            <Button
                                color="primary"
                                onClick={() => handleUpload(type)}
                                style={{ marginTop: '20px' }}
                                disabled={!uploadedFiles[type] || uploading[type]}
                            >
                                <FaCheck style={{ fontSize: '20px' }} />
                                {uploading[type] ? 'Téléchargement...' : 'Télécharger'}
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ContractDetails;
